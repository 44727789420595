import React,{ useEffect, useState } from 'react';
import { Container } from '../../globalStyles';
import {
    InfoSec, 
    InfoRow, 
    InfoColumn, 
    TextWrapper, 
    TopLine,
    Heading,
    SubTitle,
    SubTitle1,
    ImgWrapper,
    Img,
    IconBottom
} from './MessageSection.elements';
import { Icon, InlineIcon } from '@iconify/react';
import quotesIcon from '@iconify/icons-carbon/quotes';
import api from '../../services/api';

const MessageSection = ({
    lightBg , 
    imgStart, 
    lightTopLine, 
    lightTextDesc,
    description, 
    headLine, 
    lightText, 
    topLine,
    img
}) => {
    const [editor, setEditor] = useState('')
    
    useEffect(()=>{
        api.get(`/primeiradama/1`)
          .then((res) => {
              console.log("RESPONSE:", res.data)
              setEditor(res.data.descricao)
          })
      },[])
    return (
        <>
            <InfoSec lightBg={lightBg} >
                
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                
                                <Heading lightText={lightText} >
                                Mensagem de Boas Vindas
                                </Heading>
                                <Icon icon={quotesIcon} style={{color: '#e4992a', fontSize: '60px', marginLeft:'30px'}} />
                                <SubTitle lightTextDesc={lightTextDesc}
                                
                                dangerouslySetInnerHTML={{
                                    __html:editor
                                  }}>
                                    {/*<p style={{padding:'7px'}}>
                                    É com muito prazer que apresento um projecto que tem um 
grande significado para mim, que irá criar raízes e deixar marcas 
na nossa sociedade, que é realmente para todos! É abrangente, 
une o passado, o presente e o futuro. Apresento-vos a 
PLATAFORMA “DIKOTA_E6.0”.
</p>
     
                                    <p style={{padding:'7px'}}>
                                    Este é um projecto em que estou pessoalmente empenhada e 
envolvida. Eu sou uma “Dikota.”
</p>
                                    <p style={{padding:'7px'}}>
                                    “Dikota_E6.0” descrita em poucas palavras é uma plataforma de 
esperança, de dignidade, de valores, de cidadania e de encontro 
intergeracional.
                                   </p>
                                    <p style={{padding:'7px'}}>
                                    Desenvolvemos uma plataforma de construção do futuro, mas 
assente no nosso passado e nas nossas pessoas. Angola não 
pode desperdiçar os melhores ensinamentos do passado e é 
chegado o momento de as gerações mais velhas darem as 
mãos aos mais jovens para que juntos construam uma 
sociedade mais justa e inclusiva.                                    </p>
                                    <p style={{padding:'7px'}}>
                                    Este é um espaço onde queremos que todos se sintam parte 
deste nosso tão querido País e onde todos sintam que adquirem 
novos ensinamentos e experiências.

</p>
                                    <p style={{padding:'7px'}}>
                                    Esta ferramenta tecnológica é um convite a dádiva de si mesmo, 
do tempo da experiência acumulada de cada um. É um convite 
à preservação dos saberes. Todos têm um lugar indispensável 
na comunidade, do primeiro ao último dia.
                                     </p>
                                    <p style={{padding:'7px'}}>
                                    Obrigada a todos e ânimo para dar forma a este desafio que lanço.                                    
    </p>*/
 
    }
   
                                </SubTitle>
                                <IconBottom>
                                        <Icon icon={quotesIcon} style={{
                                        color: '#e4992a', 
                                        fontSize: '60px', 
                                        transform: 'rotate(180deg)',
                                    }} />
                                </IconBottom>
                                <SubTitle1>
                                    <b>ANA AFONSO DIAS LOURENÇO</b><br/>
                                    Primeira Dama da República de Angola
                                </SubTitle1>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn ImageColumn >
                            <ImgWrapper>
                                <Img src={img} />
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                
            </InfoSec>
        </>
    )
}

export default MessageSection;