import React from 'react';
import { 
    homeObjFive 
} from '../HomePage/Data';
import 
    DetailsNotice
 from '../../components/DetailsNotice/DetailsNotice';
import Navbar from "../../components/NavBar/Navbar"

const Notice = () => {
    return (
        <>
            <Navbar active = "noticias" />
            {
                window.scrollTo(0, 0)
            }
            <DetailsNotice {...homeObjFive} />
        </>
    )
}

export default Notice
