import React from 'react';
import { 
    homeObjFive 
} from '../HomePage/Data';
import { 
    DomainSection
} from '../../components';
import Navbar from "../../components/NavBar/Navbar"

const Event = () => {
    return (
        <>
            {
                window.scrollTo(0, 0)
            }
            <Navbar active = "projectos"/>

            <DomainSection {...homeObjFive} />
        </>
    )
}

export default Event
