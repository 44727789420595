import { React, useState } from 'react';
import {
    InputWrapper,
    SearchIcon,
    Input,
    Button
} from './SearchBar.elements';
import  * as Scroll from "react-scroll"

const SearchBar = (props) => {

    const [nome, setNome] = useState('');

   
    let scroller = Scroll.scroller;

    const scrollerElem = () => {
        scroller.scrollTo('profile', {
            duration: 700,
            delay: 100,
            smooth: true,
            offset: 50
        });
    }
    const handleKeyOrClick = ()=>{
        props.setBtnClick(true);
        props.handleChange(props.value); 
        scrollerElem();
        
    }

    const handleButton = () => {
        if(props.big) return props.handleClick(nome);
    }
    
    return (

        <>
            <InputWrapper big={props.big}>
                <SearchIcon />
                <Input value={props.value} big ={props.big} placeholder="Ex. Cota Pascoal Lopes..." onKeyPress={(e)=>{e.key == "Enter" && handleKeyOrClick() }} onChange={(e)=>{props.setBtnClick(false); props.setValue(e.target.value)} } />
                <Button href="#search" onClick={()=>{handleKeyOrClick()}} > Pesquisar </Button> 
            </InputWrapper>
        </>
    )
}

export default SearchBar;