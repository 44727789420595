import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
        outline: none;
        margin: 0;
        padding: 0;
        font-family: 'Montserrat', sans-serif;
    }
`;

export const Container = styled.div`
    z-index: 1;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 40px;
    padding-right: 15px;
    @media screen and (max-width: 991px){
        padding-right: ${({hero}) => (hero ? '0px': '30px')};
        padding-left: ${({hero}) => (hero ? '0px': '30px')};
    }
`;

export const Member = styled.button`
    border-radius: 4px;
    background: #fff;
    white-space: nowrap;
    padding: ${({big}) => (big ? '12px 64px': '7px 12px')};
    color: #E4992A;
    font-size: ${({fontBig}) => (fontBig ? '20px': '1.1rem')};
    font-weight: bold;
    outline: none;
    border: 1.8px solid #E4992A;
    cursor: pointer;

    &:hover {
        transition: all 0.3s ease-out;
        color: #fff;
        background: #E4992A;
    }

    @media screen and (max-width: 960px){
        width: 100%;
        padding: 12px 64px;
    }
`;

export const Button = styled.button`
    border-radius: 4px;
    background: #E4992A;
    white-space: nowrap;
    padding: ${({big}) => (big ? '12px 64px': '7px 12px')};
    margin: ${({m}) => (m ? '14px 0px 22px 0px': '0px')};
    color: #fff;
    font-size: ${({fontBig}) => (fontBig ? '20px': '1.1rem')};
    font-weight: bold;
    outline: none;
    border: 1.8px solid #E4992A;
    cursor: pointer;
    margin-left:65%;
    margin-top:2vh;

    &:hover {
        transition: all 0.3s ease-in-out;
        background: #fff;
        color: #E4992A;
    }
    
    @media screen and (max-width: 960px){
        width: 100%;
        padding: 12px 64px;
    }
    @media screen and (max-width: 768px){
        width:100%;
        height:4vh;
        font-size:2.5vw;
        text-align:center;
        margin-left:0;
        margin-top:2vh;
        padding:0;
       
    }
    
`;

export default GlobalStyle;