
import { React, useEffect, useState } from 'react';
import {
    InputWrapper,
    SearchIcon,
    Input,
    Button
} from './SearchBar.elements';

const SearchBar = (props) => {

    const handleKeyOrClick = ()=>{
        props.setAll(false);
        props.setBtnClick(true);
        props.handleChange(props.value); 
        
    }
   
    return (

        <>
            <InputWrapper big={props.big}>
                <SearchIcon />
                <Input value={props.value} big ={props.big} placeholder="Ex. Cota Pascoal Lopes..." onKeyPress={(e)=>{e.key == "Enter" && handleKeyOrClick() }} onChange={(e)=>{ props.setBtnClick(false); props.setValue(e.target.value); props.setKey(e.target.value) } } />
                <Button onClick={()=>{handleKeyOrClick()}} > Pesquisar </Button> 
            </InputWrapper>
        </>
    )
}

export default SearchBar;