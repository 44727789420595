import image from '../../img/banner3.png';
import pdama from '../../img/pdama.png';
import about from '../../img/about.png';

export const homeObjOne = {
    lightBg: false,
    primary: true,
    imgStart: '', 
    lightTopLine: true, 
    lightTextDesc: true, 
    buttonLabel: 'Tornar-se Membro', 
    description: 'Pesquise o seu DIKOTA  e saiba mais sobre suas valências', 
    headLine: 'Seja Bem-Vindo à Plataforma', 
    lightText: true, 
    topLine: 'Tags: Especialistas, Formador, Curso',
    img: image,
    alt: 'Image',
    start: '', 
};

export const homeObjTwo = {
    lightBg: true,
    primary: false,
    imgStart: 'start', 
    lightTopLine: false, 
    lightTextDesc: false, 
    buttonLabel: 'Get Started', 
    description: 'Hoje, quero apresentar-vos um projecto que me diz muito. Um projecto que irá criar raízes e deixar marcas na nossa sociedade, que é realmente para todos! É abrangente, une o passado, o presente e o futuro. Apresento-vos a PLATAFORMA “DIKOTA E_6.0”. Este é um projecto em que estou pessoalmente empenhada e envolvida. Descrita em poucas palavras, é uma plataforma de esperança, de dignidade, de valores, de cidadania e de encontro inter-geracional.Desenvolvemos uma plataforma de construção do futuro, mas com os pés bem assentes no nosso passado e nas nossas pessoas. Angola não pode desperdiçar os melhores ensinamentos do passado e, hoje, é chegado o momento de as gerações mais velhas darem as mãos aos mais jovens para que juntos construam uma sociedade mais justa e inclusiva para os angolanos. Este é um espaço onde queremos que todos se sintam parte deste nosso tão querido país e onde todos sintam que adquirem novos ensinamentos e experiências.', 
    headLine: '', //MENSAGEM DA PRIMEIRA DAMA DA REPÚBLICA
    lightText: false, 
    topLine: 'Ana Afonso Dias Lourenço',
    img: pdama,
    alt: 'Image',
    start: '', 
};

export const homeObjThree = {
    lightBg: false,
    primary: true,
    imgStart: '', 
    lightTopLine: true, 
    lightTextDesc: true, 
    buttonLabel: 'Saber Mais', 
    description: 'A plataforma Dikota Experiência 6.0 é uma iniciativa da Primeira Dama da República de Angola, concebida para dar resposta aos anseios de um grupo da Sociedade Civil; consubstanciada em um espaço de diálogo para todos aqueles que são parte interessada na valorização do ser, do saber e da experiência acumulada das gerações seniores.', 
    headLine: 'Sobre o Dikota', 
    lightText: true, 
    topLine: 'Entende-se que a referida Plataforma, na sua materialização, irá mobilizar e sensibilizar a sociedade em geral, em particular os mais jovens, os líderes, os formadores de opinião e decisores, a considerarem o valor e a importância do aprendizado de vida dos cidadãos com mais de 60 anos.',
    img: about,
    alt: 'Image',
    start: '', 
}

export const homeObjFour = {
    lightBg: true,
    primary: false, 
    imgStart: 'start', 
    lightTopLine: false, 
    lightTextDesc: false, 
    buttonLabel: 'Get Started', 
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque volutpat ut mi quis lobortis. Quisque dapibus ex ligula, non mattis risus blandit in. Vestibulum sit amet molestie felis. Aliquam tincidunt in ante quis ultrices. Curabitur gravida nunc placerat enim accumsan ultricies. Vestibulum ullamcorper porttitor diam vitae viverra. Nunc sodales lectus dolor, id vestibulum risus ultricies et. Proin sit amet suscipit purus. Nunc ultrices tincidunt dolor vitae feugiat', 
    headLine: 'SOBRE', 
    lightText: false, 
    topLine: 'Margeting Agency',
    img: require('../../img/svg-3.svg'),
    alt: 'Image',
    start: '', 
}

export const homeObjFive = {
    lightBg: true,
    primary: false,
    imgStart: 'start', 
    lightTopLine: false, 
    lightTextDesc: false, 
    buttonLabel: 'Simule Já', 
    description: 'Em até 1 dia útil, após aprovação do contrato e documentos, o dinheiro está na sua conta.', 
    headLine: 'Domínios de Actuação', 
    lightText: false, 
    topLine: '',
    img: require('../../img/fast.svg'),
    alt: 'Image',
    start: '', 
    knowledgeDescriptio: `Promover a criação de uma Bolsa de Seniores Angolanos, de reconhecida experiência, e a prestação de serviços de Consultoria, Coaching, Formação Académica ou Profissional, a instituições e a empresas privadas ou públicas.
    Promover uma maior participação das gerações seniores nas Instituições de Ensino e Formação Profissional na qualidade de professores e formadores convidados.
    Promover a transmissão da experiência e do conhecimento de seniores angolanos, mediante a realização e /ou a sua participação em seminários, palestras nas mais diversas áreas. 
    Promover a preservação da memória colectiva, do património cultural e legado intelectual, com actividades a propor, como por exemplo, exposições de acervos.`, 
    mentoringDescriptio: `Apoiar a participação de Seniores em programas de desenvolvimento pessoal e empresarial, junto de associações juvenis e entidades como incubadoras de empresas, como forma de transmissão de conhecimento e experiência professional.
    Apoiar um programa de indução e imersão profissional dos novos quadros da Administração Pública e do Sector Empresarial público e privado ministrado por Seniores.`,
    educationDescriptio: `Apoiar o desenvolvimento de programas de educação/formação dirigidos às gerações seniores, em diversas áreas do conhecimento, em particular no domínio das competências digitais.
    Sensibilizar as gerações seniores para o exercício da cidadania e protecção contra todas as formas de discriminação social, e divulgação de informação relativa a leis, normas, direitos e medidas relacionadas com o tema.`,
    investigationDescriptio: `Promover projectos de investigação científica sobre o envelhecimento activo, qualidade de vida e bem-estar nas gerações seniores.
    Incentivar a formação especializada e contínua dos profissionais de saúde no âmbito da geriatria e gerontologia, tendo em conta a carência destas especialidades no País, mediante parcerias ou incentivo a instituições de ensino e formação. 
    Incentivar o desenvolvimento de projectos de investigação e estudos nas diversas áreas do conhecimento por parte das gerações seniores. `,
};