import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
import { Container } from '../../globalStyles';
import {
    InfoSec, 
    InfoRow, 
    InfoColumn, 
    TextWrapper, 
    TopLine,
    Heading,
    Form,
    Input,
    Button,
    Span
} from './InviteSection.elements';
import Accordion from '../Accordion/Accordion';
import api from "./../../services/api";

const InviteSection = ({
    primary, 
    lightBg , 
    imgStart, 
    lightTopLine, 
    lightTextDesc, 
    buttonLabel, 
    description, 
    headLine, 
    lightText, 
    topLine,
    img
}) => {
    const history = useHistory();

    const [nome, setNome] = useState("");
    const [nif, setNif] = useState("");
    const [morada, setMorada] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [site, setSite] = useState("");
    const [comentario, setComentario] = useState("");

    async function handleSubmit(event) {
        event.preventDefault();

        if (!nome || !nif || !morada || !email || !telefone || !site || !comentario) {
            swal("Preencha todos os campos!", "Click no botão para tentar novamente!", "warning");

            return;
        }

        const data = new FormData();

        data.append("nome", nome);
        data.append("morada", morada);
        data.append("nif", nif);
        data.append("email", email);
        data.append("telefone", telefone);
        data.append("site", site);
        data.append("comentario", comentario);

        await api.post("parceiros", data)
        .then(() => {
            swal("Cadastro realizado com sucesso!", "Click no botão para fechar!", "success");

            setNome("");
            setNif("");
            setMorada("");
            setEmail("");
            setTelefone("");
            setSite("");
            setComentario("");
        })
        .catch(() => {
            swal("Erro ao cadastrar, Verifique os seus dados!", "Click no botão para tentar novamente!", "error");
        });
    }

    return (
        <>
            <InfoSec lightBg={lightBg} >
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText} >
                                    Convite a<br/>
                                    <span className='parcerias'>Parcerias</span>
                                </Heading>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn ImageColumn >
                            <Heading form>
                                    Preencha os campos vazios
                            </Heading>
                            <Form onSubmit={handleSubmit}>
                                <Input
                                    value={nome} 
                                    placeholder="Empresa" 
                                    name="nome" 
                                    onChange={(e) => setNome(e.target.value)}
                                    autoComplete="off"
                                />
                                <Input 
                                    value={nif} 
                                    placeholder="NIF"
                                    name="nif"
                                    onChange={(e) => setNif(e.target.value)}
                                    autoComplete="off"
                                />
                                <Input 
                                    value={morada} 
                                    placeholder="Morada" 
                                    name="morada"
                                    onChange={(e) => setMorada(e.target.value)}
                                    autoComplete="off"
                                />
                                <Input 
                                    value={telefone} 
                                    placeholder="Telefone" 
                                    nome="telefone"
                                    onChange={(e) => setTelefone(e.target.value)}
                                    autoComplete="off"
                                    type="number"
                                />
                                <Input
                                    value={email} 
                                    placeholder="Email" 
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="off"
                                    type="email"
                                />
                                <Input 
                                    value={site} 
                                    placeholder="Site"
                                    name="site"
                                    onChange={(e) => setSite(e.target.value)} 
                                    autoComplete="off"
                                />
                                <Input 
                                    area 
                                    value={comentario} 
                                    placeholder="Comentário"
                                    name="comentario"
                                    onChange={(e) => setComentario(e.target.value)} 
                                    autoComplete="off"
                                />
                                <Button type="submit"> Enviar </Button>
                            </Form>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default InviteSection;
