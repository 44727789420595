import { React, useState, useEffect } from 'react';
import swal from 'sweetalert';
import { Container } from '../../globalStyles';
import eventImage_1 from '../../img/mulher.jpg';
import eventImage_2 from '../../img/homem2.png';
import eventImage_3 from '../../img/homem.jpg';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar'
import * as Scroll from "react-scroll"
import Tablesearch from './TableSeach'

import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    SubTitle,
    Heading,
    DivColumn,
    CardImage,
    Card,
    BottomLine,
    CardBody,
    Perfil,
    PerfilRow,
    PerfilColumn,
    PerfilImage,
    PerfilWrapper,
    TextBio,
    MessageBio,
    Button,
    Input,
    TableContainer,
    P
} from './SearchSection.elements';
import SearchBar from './SearchBar';
import api from "./../../services/api";

// const dikotas = [
//     {
//         photo: eventImage_1,
//         name: 'Fernanda Zita',
//         age: 70,
//         gender: 'Feminino',
//         location: 'Nelito Soares',
//         grade: 'Médica',
//         interest: 'Análises Clínicas',
//         email: 'fernanda@gmail.com',
//     },
//     {
//         photo: eventImage_2,
//         name: 'Rui Veloso',
//         age: 62,
//         gender: 'Masculino',
//         location: 'Nelito Soares',
//         grade: 'Médico',
//         interest: 'Análises Clínicas',
//         email: 'veloso@gmail.com',
//     },
//     {
//         photo: eventImage_3,
//         name: 'Fernando Manuel',
//         age: 42,
//         gender: 'Masculino',
//         location: 'Nelito Soares',
//         grade: 'TI',
//         interest: 'Análise De Sistemas',
//         email: 'apoli@gmail.com',
//     }
// ];

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);





const useStyles = makeStyles({
    table: {
        minWidth: 700
    },
});
const SearchSection = (props) => {

     
    const classes = useStyles();
    
    const [dikotas, setDikotas] = useState();

    useEffect(() => {
      
        // console.log(props.value)
        props.btnClick && props.setBtnClick(props.btnClick);
        // console.log("clicou no search ", props.btnClick);
        props.btnClick && props.handleChange(props.value);
    }, [props.btnClick])

    const handleAll = async () => {
        props.setBtnClick(false);
        props.setAll(!props.all);

        const result = await api.get(`seniores`);


        const newResult = result.data.map(dikota => {
            return {
                photo: `https://dikota.ao/backend/api/u/${dikota.foto}`,
                id: dikota.id,
                name: dikota.nome,
                ultimo_nome: dikota.sobrenome,
                age: dikota.idade,
                gender: dikota.genero,
                location: dikota.morada,
                grade: dikota.formacao,
                interest: dikota.interesse,
                email: dikota.email,
                percurso_academico: dikota.percurso_academico,
                percurso_profissional: dikota.percurso_profissional
            }
        });

        setDikotas(newResult);
    }


    let Element = Scroll.Element;
    let scroller = Scroll.scroller;


    
 

    return (
        <div id="search">
            <InfoSec >
                <InfoRow >
                    <InfoColumn center>
                        <DivColumn >
                            <SearchBar value={props.value} btnClick={props.btnClick} setValue={props.setValue} setBtnClick={props.setBtnClick} setKey={props.setSearchKey} setAll={props.setAll} all={props.all} big handleChange={props.handleChange} />
                            <P onClick={ () => handleAll()} style={{textAlign:"left", marginTop:"20px" , textDecoration:"none"}}> {!props.all ? "Listar Todos Dikotas" : "Ocultar todos"} </P>
                        </DivColumn>
                    </InfoColumn>
                    {
                        props.searchKey && props.btnClick  &&
                        <TextWrapper>
                            
                            <SubTitle>
                                Resultado da Pesquisa: "<b>{props.searchKey}</b>"<br/> Total : <b>{props.dikotas.length}</b>
                            </SubTitle>
                        </TextWrapper>
                    }
                    {
                        props.all  &&
                        <TextWrapper>
                            
                            <SubTitle>
                                Resultado de todos
                            </SubTitle>
                        </TextWrapper>
                    }
                    {
                        props.searchKey && props.btnClick &&  props.dikotas.length > 0  && (
                            <InfoColumn style={{display:'flex', justifyContent:'center'}}>
                                <TableContainer component={Paper}>





                                    {props.btnClick && props.searchKey && (

                                        <Tablesearch dikotas={props.dikotas} />

                                    )}

                                </TableContainer>
                                {
                                    (props.detailsIndex !== undefined) &&
                                    <div id="containerId">
                                            <Perfil className='perfil'>
                                                <PerfilRow>
                                                    <PerfilColumn bg></PerfilColumn>
                                                </PerfilRow>
                                                <PerfilRow className='perfilRow' relative>
                                                    <PerfilColumn className='perfilColumn'>
                                                        <PerfilImage className='perfilImage' src={props.dikotas[props.detailsIndex].photo} />
                                                        <PerfilWrapper className='perfilWrapper'>
                                                            <TextBio className='textbio'>
                                                                <Heading className='heading'> {props.dikotas[props.detailsIndex].name} </Heading>
                                                                <SubTitle className='subtitle'> {props.dikotas[props.detailsIndex].interest} </SubTitle>
                                                                <BottomLine className='bottomline'> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris iaculis sodales lacus vel sollicitudin. Proin sit amet vulputate sem. Aliquam hendrerit turpis lorem, eget lobortis elit posuere quis. Fusce eget commodo odio. Ut dignissim id arcu a venenatis. Morbi pellentesque tellus orci, ut blandit dolor rutrum sit amet. Vestibulum blandit enim lectus, iaculis vestibulum augue consequat sed. Pellentesque pretium ligula turpis, et feugiat ex ullamcorper interdum. </BottomLine>
                                                            </TextBio>
                                                            <MessageBio>
                                                                <Button type="submit" form="mensagem"> Enviar Mensagem </Button>
                                                                <Button> Mais... </Button>
                                                                <SubTitle> Trabalho: SSS </SubTitle>
                                                                <SubTitle> Universidade: aaa </SubTitle>
                                                                <form id="mensagem" onSubmit={(e) => props.handleSubmit(e, props.dikotas[props.detailsIndex].id)}>
                                                                    <Input
                                                                        type="area"
                                                                        area
                                                                        name="mensagem"
                                                                        onChange={(e) => props.setMensagem(e.target.value)}
                                                                    />
                                                                </form>
                                                            </MessageBio>
                                                        </PerfilWrapper>
                                                    </PerfilColumn>
                                                </PerfilRow>
                                            </Perfil>
                                    </div>
                                }

                            </InfoColumn>)}

                            {
                            props.all  && (
                                <InfoColumn style={{display:'flex', justifyContent:'center'}}>
                                    <TableContainer component={Paper}>





                                        <Tablesearch dikotas={dikotas} />

                                </TableContainer>
                                

                            </InfoColumn>)}
                </InfoRow>
            </InfoSec>
        </div>
    )
}

export default SearchSection;