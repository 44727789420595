import React from 'react';
import { Container } from '../../globalStyles';
import {
    InfoSec, 
    InfoRow, 
    InfoColumn, 
    TextWrapper, 
    TopLine,
    Heading,
    Form,
    Input,
    Button
} from './QuestionSection.elements';
import Accordion from '../Accordion/Accordion';

const items = [
    {
        heading: "Angola",
        content: "Item 1"
    },
    {
        heading: "Angola 2",
        content: "Item 2"
    },
    {
        heading: "Angola 3",
        content: "Item 3"
    }
];

const QuestionSection = ({
    primary, 
    lightBg , 
    imgStart, 
    lightTopLine, 
    lightTextDesc, 
    buttonLabel, 
    description, 
    headLine, 
    lightText, 
    topLine,
    img
}) => {

    return (
        <>
            <InfoSec lightBg={lightBg} >
                <Container>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText} >
                                    Perguntas Frequentes
                                </Heading>
                                <Accordion />
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn ImageColumn >
                            <Heading lightText={lightText} >
                                    Fale Connosco
                            </Heading>
                            <Form>
                                <TopLine lightTopLine={lightTopLine}>
                                    Email
                                </TopLine>
                                <Input />
                                <TopLine lightTopLine={lightTopLine}>
                                    Mensagem
                                </TopLine>
                                <Input area />
                                <Button> Enviar Mensagem</Button>
                            </Form>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default QuestionSection;