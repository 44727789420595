import styled, { keyframes } from 'styled-components';

const opacity = keyframes`
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
`;

export const ModalElement = styled.div`
  visibility: ${({ closed }) => (closed ? `hidden` : `visible`)};

  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  overflow: auto; 

  display: flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(0,0,0,.5);

  animation: ${opacity} 0.9s;
  
  width: 100%;
  margin-left:0vw;
  margin-top:0vw;
  




`;

export const ModalContent = styled.div`
  max-width: 1100px;
  min-height: 500px;

  /* border: 2px solid #E4992A; */
  border-radius: 5px;
  background-color: #FFF;

  padding: 20px 16px;
 
  @media screen and (max-width: 320px){
    width: 90%;
    margin-left:7.5vw;
    margin-top:20vw;

  
  }

  @media screen and (max-width: 414px){
    margin-top:35vw;
  }
  @media screen and (max-width: 375px){
    margin-top:75vw;
  }
  @media screen and (max-width: 768px){
    width: 80%;
  }
`;

export const ModalHeader = styled.div`
  margin-bottom: 20px;

  display: flex;
  justify-content: flex-end;
`;

export const CloseButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: bold;
  color: #E4992A;

  text-align: center;

  @media screen and (max-width: 320px){
    font-size: 24px;
  }

  @media screen and (max-width: 768px){
    font-size: 24px;
  }
  
`;

export const Text = styled.p`
  color: #838383;
  font-weight: medium;
  font-size: 18px;
  line-height: 1.7;
  margin-bottom: 16px;

  @media screen and (max-width: 768px){
    font-size: 15px;
  }

  @media screen and (max-width: 320px){
    font-size: 24px;
  }
  
`;