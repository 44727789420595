import React,{ useState } from 'react' 
import { Link } from 'react-router-dom'
import { Container, Button } from '../../globalStyles';
import image from '../../img/logo2.png';
import img1 from '../../img/banner3.png';
import SlideShow from '../Slide/Slide';
import {
    InfoSec, 
    InfoRow, 
    InfoColumn, 
    TextWrapper, 
    TopLine,
    Heading,
    SubTitle,
    ImgWrapper,
    Div
} from './Hero.elements';
import SearchBar  from './SearchBar';

const Hero = ({
    primary, 
    lightBg , 
    imgStart, 
    lightTopLine, 
    lightTextDesc, 
    buttonLabel, 
    description, 
    headLine, 
    lightText, 
    topLine,
    img,
    handle,
    value,
    setValue,
    click,
    setClick,
    setBtnClick,
    btnClick,
    ...props
}) => {
  
    return (
        <div id="home">
            <InfoSec lightBg={lightBg} >
                <Container hero>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                
                                <Heading lightText={lightText} >
                                    Seja Bem-Vindo
                                    <Heading className='plataforma' lightText={lightText} >
                                    <span style={{fontSize: '46px', fontWeight: 'bold'}} >à Plataforma</span>
                                </Heading>
                                </Heading>

                                
                                
                                <img  src={image} width="80%" />
                                <SubTitle lightTextDesc={lightTextDesc} min>
                                    {description}
                                </SubTitle>
                                <SearchBar scroller={props.scrollerElem} btnClick={btnClick} setBtnClick={setBtnClick} setValue={setValue} value={value} big handleChange={props.handleChange} />
                                <TopLine lightTopLine={lightTopLine}>
                                    {topLine}
                                </TopLine>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn ImageColumn >
                            <ImgWrapper>
                                <SlideShow style={{position: 'relative'}} />
                            </ImgWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </div>
    )
}

export default Hero
