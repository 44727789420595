import styled from 'styled-components';
import  OldAvatar from '@material-ui/core/Avatar';

export const InfoSec = styled.div`
    text-align: center;
    background-color: #fff;
    padding: 0px 0px 60px 0px;

    .avatar{
        display: flex;
        justify-content: center;
    }
`;

export const InfoRow = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: -60px;
    @media screen and (max-width: 768px){
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    flex-direction: column;
`;

export const InfoColumn = styled.div`
    margin: ${({ center }) => (center ? '0px' : '0px 15px 15px 15px')};
    padding: 30px;
    @media screen and (max-width: 768px){
        width: 100%;
        margin: 0px;

        .perfilColumn{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px;
        }
        .perfilWrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0px;
        }

        .textbio{
            display: flex;
            flex-direction: column;
            padding: 0px;
            align-items: center;
        }

        .heading{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
        }

        .subtitle{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 10px;
        }

        .bottomline{
            width: 250px;
            padding-bottom: 120px;
        }

        .messagebio{
            width: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px;
        }

    }
`;

export const Perfil = styled.div`
    background-color: #fff;
    padding: 10px 10px 20px 10px;
`;
export const PerfilRow = styled.div`
    display: flex;
    width: 100%;
    position: ${({ relative }) => (relative ? 'relative' : 'none')};
    top: ${({ relative }) => (relative ? '-100px' : 'none')};
    @media screen and (max-width: 768px){
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    flex-direction: column;
`;
export const PerfilColumn = styled.div`
    text-align: left;
    padding: ${({ bg }) => (bg ? '20px' : '10px 120px')};
    width: 100%;
    height: ${({ bg }) => (bg ? '35vh' : 'none')};
    background: ${({ bg }) => (bg ? '#feefda' : 'none')};
    border-radius: ${({ bg }) => (bg ? '20px' : 'none')};
    @media screen and (max-width: 768px){
        width: 100%;
        margin: 0px;
    }
`;

export const PerfilImage = styled.img`
    width: 180px;
    height: 180px; 
    border-radius: 10px;
    background-color: #fddca9;
`;

export const PerfilWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px){
        
    }
`;

export const TextBio = styled.div`
    width: 100%;
    text-align: left;
    padding: 0px 80px 0px 0px;
`;

export const MessageBio = styled.div`
    width: 100%;
    text-align: right;
    position: relative;
    top: -90px;
    
`;

export const TextWrapper = styled.div`
    width: 100%;
    padding: 16px 40px;
    background: #ececec;
    text-align: left;
    @media screen and (max-width: 768px){
        padding-bottom: 55px;
    }
`;

export const Heading = styled.h1`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #E4992A;
    cursor: pointer;
`;

export const SubTitle = styled.p`
    width: 80%;
    font-size: 24px;
    font-weight: 100;
    color: #E4992A;
    margin-left: 7%;
    @media screen and (max-width: 768px){
        max-width: 100%;
        text-align: left;
        padding: 8px;
        margin-left: -3n%;
    }
`;

export const BottomLine = styled.div`
    color: #838383;
    text-align:justify;
    font-size: 14px;
    margin:10px;
`;

export const CardWrapper = styled.div`
    width: 100%;
`;

export const Card = styled.div`
    text-align: left !important;
    width: 100%;
    margin: 25px 18px;
    padding-bottom: 10px;
    background: #FFFFFF;
    border-bottom: 2px solid #ccc;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px){
        margin: 25px 0px;
    }
`;

export const CardImage = styled.img`
    width: 120px;
    height: 140px; 
    border-radius: 10px;
`;

export const CardBody = styled.div`
    width: 50%;
    padding: 0px 30px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    @media screen and (max-width: 768px){
        width: 100%;
        padding: -50px 10px 0px 10px;
        margin-top: -10px;
    }
`;

export const DivColumn = styled.div`
    width: 50%;
    
    @media screen and (max-width: 768px){
        width: 100%;
        
    }
`;

export const Input = styled.input`
    width: 100%;
    background: #e6e6e6;
    min-height: ${({ area }) => (area ? '180px' : '30px')};
    box-sizing: border-box;
    transition: 0.3s;
    padding: 14px 0px 14px 50px;
    margin-bottom: 10px;
    border: 1px solid #F4F4ED;
    border-radius: 10px;

    &:focus {
        border: 1px solid orange;
        box-shadow: 0 0 8px 0 orange;
    }
`;

export const Button = styled.button`
    width: 20%;
    border-radius: 10px;
    background: #E4992A;
    white-space: nowrap;
    padding: ${({big}) => (big ? '12px 64px': '5px 0px')};
    margin: 14px 0px 14px 78%;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    outline: none;
    border: 1.8px solid #E4992A;
    cursor: pointer;

    &:hover {
        transition: all 0.3s ease-in-out;
        background: #fff;
        color: #E4992A;
    }

    @media screen and (max-width: 960px){
        width: 100%;
        margin: 0px;
    }
`;

export const DivG = styled.div`
    display: flex;

    @media screen and (max-width: 960px){
        display: flex;
        flex-direction: column;
    }
`;

export const DivS = styled.div`
    width: 50%;
    margin: 0px 8px; 
    @media screen and (max-width: 960px){
        width: 100%;
        margin: 0px;
    }
`;

export const DivM = styled.div`
    width: 50%;
    margin: 0px 8px; 
    @media screen and (max-width: 960px){
        width: 100%;
        margin: 0px;
    }
`;

export const TableContainer = styled.div`
    width: 86.5%;
    @media screen and (max-width: 960px){
        width: 100%;
    }

`;

export const TableNewRow = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 20px;
    align-items: center;
    @media screen and (max-width: 768px){
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
`;

export const TableNewColumn = styled.div`
    width: 50px;
    flex-basis: 50%;
    @media screen and (max-width: 960px){
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px 0px 0px 0px;
    }
`;


export const P = styled.p`
    margin-top: 2px;
    cursor: pointer;
    color: #E4992A;
    margin-left: 15%;
    @media screen and (max-width: 768px){
        margin-left: 0%;
    }
`;
