import React, {useState} from 'react';
import swal from 'sweetalert';
import  * as Scroll from "react-scroll"

import { 
    homeObjOne, 
    homeObjTwo, 
} from './Data';
import { 
    Hero, 
    MessageSection, 
    SearchSection,
    InviteSection
} from '../../components';
import Navbar from "../../components/NavBar/Navbar"
import { PinDropSharp } from '@material-ui/icons';
import api from "./../../services/api";

const Home = (props) => {
    const[value, setValue] = useState('');
    const  [valueInput,setValueInput] = useState("")
    const [btnClick, setBtnClick] = useState(false);
    const [all, setAll] = useState(false);
   
    const [dikotas, setDikotas] = useState([]);

    const [detailsIndex, setDetailsIndex] = useState(undefined);


    const [mensagem, setMensagem] = useState("");

    const [searchKey, setSearchKey] = useState("");

    let Element = Scroll.Element;
    let scroller = Scroll.scroller;


    async function handleSubmit(event, id) {
        event.preventDefault();

        const data = new FormData();

        data.append("senior_id", id);
        data.append("mensagem", mensagem);

        await api.post("mensagens", data);

        swal("Mensagem enviada com sucesso!", "Click no botão para fechar!", "success")
    }
    const scrollerElem = () => {
        scroller.scrollTo('profile', {
            duration: 700,
            delay: 100,
            smooth: true,
            offset: 50
        });
    }
    async function handleChange(searchKey) {

        



        if (!searchKey) {
            setSearchKey("");

            setDikotas([]);

            setDetailsIndex(undefined);

            return;
        }

        if ( valueInput.length < 1) setBtnClick(false);

        
    
        setSearchKey(valueInput);
        setDetailsIndex(undefined);

        const result = await api.get(`seniores/${searchKey}`);


        const newResult = result.data.map(dikota => {
            return {
                photo: `https://dikota.ao/backend/api/u/${dikota.foto}`,
                id: dikota.id,
                name: dikota.nome,
                ultimo_nome: dikota.sobrenome,
                age: dikota.idade,
                gender: dikota.genero,
                location: dikota.morada,
                grade: dikota.formacao,
                interest: dikota.interesse,
                email: dikota.email,
                percurso_academico: dikota.percurso_academico,
                percurso_profissional: dikota.percurso_profissional
            }
        });

        setDikotas(newResult);


    }
    // searchBarValue ? setDikotas(searchBarValue) : "" ;
    

    return (
        <>
          
            <Navbar active = "home"/>
            <Hero scroller={scrollerElem} value={value} setValue={setValue} dikotas={dikotas} setDikotas={setDikotas} detailsIndex={detailsIndex} setDetailsIndex={setDetailsIndex} mensagem={mensagem} setMensagem={setMensagem} searchKey={searchKey} setSearchKey={setSearchKey} btnClick={btnClick} setBtnClick={setBtnClick} setValue={setValueInput} value={valueInput} {...homeObjOne} result={value}  handleChange={handleChange}  all={all} setAll={setAll}   handleSubmit={handleSubmit}/>
            <MessageSection {...homeObjTwo} />
            <InviteSection />

            <Element name="profile">
                <SearchSection scroller={scrollerElem} value={value} setValue={setValue} dikotas={dikotas} setDikotas={setDikotas} detailsIndex={detailsIndex} setDetailsIndex={setDetailsIndex} mensagem={mensagem} setMensagem={setMensagem} searchKey={searchKey} setSearchKey={setSearchKey} btnClick={btnClick} setBtnClick={setBtnClick}  setValue={setValueInput} value={valueInput}  {...homeObjOne} result={value} handleChange={handleChange}  all={all} setAll={setAll}   handleSubmit={handleSubmit} />
            </Element>
            
        </>
    )
}

export default Home
