import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';

export const InputWrapper = styled.div`
    display: flex;
    position: relative;
    margin-left: 15%;
    @media screen and (max-width: 960px){
        margin-left: 0px;
    }
    
`;

export const SearchIcon = styled(FaSearch)`
    color: #fff;
    width: 70px;
    height: 30px;
    position: absolute;
    left: -12px;
    top: 12px;
    transition: 0.3s;
`;

export const Input = styled.input`
    border-radius: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    transition: 0.3s;
    padding: 17px 0px 17px 50px;
    border: none;
    background: #f2f2f0;
    &:focus {
        border: 1px solid orange;
        box-shadow: 0 0 8px 0 orange;
    }
`;

export const Button = styled.a`
    text-decoration: none;
    position: absolute;
    right: 0px;
    border-radius: 10px;
    background: #f3f1c5;
    white-space: nowrap;
    padding: 15px 30px;
    color: #E4992A;
    font-size: 1.1rem;
    font-weight: bold;
    outline: none;
    border: none;
    cursor: pointer;

    @media screen and (max-width: 960px){
        padding: 13px 20px;
    }

    &:hover {
        transition: all 0.3s ease-out;
        color: #fff;
    }
`;

