import styled from 'styled-components';

import AnchorLink from 'react-anchor-link-smooth-scroll';

export const InfoSec = styled.div`
    color: #fff;
    background: #FBB03B;
    padding: 18px;
    
    @media screen and (max-width: 768px){
        padding: 40px 0px;
    }
`;

export const InfoRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 -15px -30px;
    @media screen and (max-width: 768px){
        margin: 0px;
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }   
    flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse': 'row')};
`;

export const InfoColumn = styled.div`
    margin-bottom: 15px;
    margin-right: ${( { max } ) => ( max ? '50px': '15px')};
    flex: 1;
    max-width: ${( { min } ) => ( min ? '10%': '25%')};
    flex-basis: ${( { min } ) => ( min ? '10%': '25%')};
    @media screen and (max-width: 768px){
        margin: 0px;
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const TextWrapper = styled.div`
    
    padding-top: 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px){
        width: 100%;
        max-width: 100%;
        padding-bottom: 20px;
    }
`;

export const TextWrapper2 = styled.div`
    
    padding-top: 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px){
        width: 100%;
        max-width: 100%;
    }
`;

export const Heading = styled.h1`
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: medium;
    color: #FFF;
`;

export const  SubTitle = styled.div`
    color: ${({ orange }) => ( orange ? '#E4992A' : '#fff')};
    font-weight: medium;
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 5px;
`;


export const Input = styled.input`
    width: 100%;
    min-height: 56px;
    box-sizing: border-box;
    border-radius: 5px;
    transition: 0.3s;
    padding: 14px 0px 14px 50px;
    margin-bottom: 10px;
    border: none;

    &:focus {
        border: 1px solid orange;
        box-shadow: 0 0 8px 0 orange;
    }
`;


export const Button = styled.button`
    width: 50%;
    border-radius: 8px;
    background: #E4992A;
    white-space: nowrap;
    padding: ${({big}) => (big ? '12px 64px': '14px 12px')};
    margin: 14px 0px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    outline: none;
    border: 1.8px solid #E4992A;
    cursor: pointer;

    &:hover {
        transition: all 0.3s ease-in-out;
        background: #fff;
        color: #E4992A;
    }

    @media screen and (max-width: 960px){
        width: 100%;
    }
`;
export const FooterWrapper = styled.div`
    padding-top: 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px){
        padding-bottom: 20px;
    }
`;

export const  FooterText = styled.div`
    color: #FFF;
    font-weight: medium;
    font-size: 14px;
    line-height: 1.7;
    margin-bottom: 5px;
`;

export const NavLinks = styled.a`
    color: #FFF;
    text-decoration: none;
    height: 100%;
`;
