import styled from 'styled-components';

export const InfoSec = styled.div`
    color: #fff;
    padding: 90px 0;
    background: #fed79e;
`;

export const InfoRow = styled.div`
    display: flex;
    margin: 0 50px 0px -6%;
    align-items: center;
    @media screen and (max-width: 768px){
        margin: 0px;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse': 'row')};
`;

export const InfoColumn = styled.div`
    margin-bottom: 15px;
    flex: 1;
    max-width: 47%;
    flex-basis: 50%;
    background-color: ${({ImageColumn}) => (ImageColumn ? '#fff': 'none')};
    padding: 12px 12px 12px 22px;
    @media screen and (max-width: 768px){
        margin: 0px;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        flex-direction: ${({ImageColumn}) => (ImageColumn ? 'column': 'row')};
    }
`;

export const TextWrapper = styled.div`
    padding: 0px 50px;
    align-items: center;
    
    @media screen and (max-width: 768px){
        padding: 0px 0px 50px 0px;
        margin: 0px;
        max-width: 100%;
    }
`;

export const  TopLine = styled.div`
    color: #E4992A;
    font-weight: 900;
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 6px;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    margin-bottom: 30px;
    margin-top: ${({form}) => (form ? '20px': '0px')};;
    font-size: ${({form}) => (form ? '27px': '60px')};
    color: ${({form}) => (form ? '#e0921c': '#fff')};
    font-weight: 400;
    text-align: center;
    .parcerias{
        font-size: 80px;
        font-weight: bold;
    }
    @media screen and (max-width: 1024px){
        .parcerias{
            font-size: 65px;
        }
        font-size: 50px;
        width: 100%;
    }
    @media screen and (max-width: 768px){
        .parcerias{
            font-size: 50px;
        }
    }
`;

export const Span = styled.span`
    
    font-weight: bold;
    font-size: 100px;

    @media screen and (max-width: 960px){
        font-size: 60px;
    }
`;

export const Form = styled.form`
    display: inline-block;
    @media screen and (max-width: 960px){
        display: block;
    }
`;

export const Input = styled.input`
    width: ${({ area }) => ( area ? '98%': '48%')};
    min-height: ${({ area }) => ( area ? '180px': '30px')};
    box-sizing: border-box;
    transition: 0.3s;
    padding: 14px 0px 14px 50px;
    margin: 0px 10px 10px 0px;
    margin-bottom: 10px;
    border: 1px solid #F4F4ED;
    border-radius: 10px;
    background: #f2f2f0;

    &:focus {
        border: 1px solid orange;
        box-shadow: 0 0 8px 0 orange;
    }

    @media screen and (max-width: 1024px){
        width: 100%;
    }
    @media screen and (max-width: 960px){
        width: 100%;
        box-sizing: none;
        border: none;
        margin-left: -3px;
    }
`;


export const Button = styled.button`
    width: 20%;
    border-radius: 10px;
    background: #E4992A;
    white-space: nowrap;
    padding: ${({big}) => (big ? '12px 64px': '5px 0px')};
    margin: 14px 0px 14px 78%;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    outline: none;
    border: 1.8px solid #E4992A;
    cursor: pointer;

    &:hover {
        transition: all 0.3s ease-in-out;
        background: #fff;
        color: #E4992A;
    }

    @media screen and (max-width: 960px){
        width: 100%;
        margin: 0px;
    }
`;

