import React, { useState, useEffect } from 'react';
import { Container } from '../../globalStyles';

import {
    Geral,
    InfoRow,
    InfoColumn,
    TextWrapper,
    Heading,
    BottomLine,
    MiddleLine,
    SubTitle,
    Card,
    CardImage,
    CardBody,
    Div,
    P
} from './DomainSection.elements';
import Modal from '../Modal/Modal';
import eventImage_1 from '../../img/4.jpg';
import eventImage_3 from '../../img/sr_livro.jpeg';
import eventImage_2 from '../../img/jovem_dikota.jpeg';
import GetAppIcon from '@material-ui/icons/GetApp';
import api from '../../services/api'
import { Spinner } from "@chakra-ui/react"

const DomainSection = ({
    lightText, 
    lightTextDesc
}) => {
    const [modalData, setModalData] = useState({});

    const [closedModal, setClosedModal] = useState(true);
    const [response, setResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const handleModal = (data) => {        
        setModalData(data);

        setClosedModal(false);
    }

    function setClosedOfParent() {
        setClosedModal(true);
    }
    useEffect(()=>{
        api.get(`/projectos`)
        .then((res)=>{
            setResponse(res.data);
            setIsLoading(false)
        })
    },[])
    return (
        <div id="domain" >
            <Geral>
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText} >
                                    CONHEÇA OS PROJECTOS – BANDEIRA DESTA INICIATIVA
                                </Heading>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoRow>
                            {
                                (response.length > 0)
                                ?
                                response.map((elem, index)=>(
                                    <Card>
                                    <CardImage src={`https://dikota.ao/backend/uploads/${elem.img}`} />
                                    <CardBody>
                                        <SubTitle lightTextDesc={lightTextDesc} orange>
                                            {elem.nome}
                                        </SubTitle>
                                        <BottomLine>Descrição do Projecto:</BottomLine>
                                        <MiddleLine> {elem.descricao}</MiddleLine>
                                        {/*
                                            <Div>
                                                <a style={{color:'#E4992A', cursor:'pointer', display:'flex'}}>
                                                <GetAppIcon style={{color:'#E4992A', marginRight:'5px'}} />
                                                <P>Descarregar Projecto</P>
                                                </a>
                                            </Div>
                                        */}
                                        </CardBody>
                                    </Card>
                                ))
                                :
                                isLoading
                                ?
                                <Spinner style={{width:'70px', height:'70px'}} color={'#E4992A'}/>
                                :
                                <h3>Nenhum projecto encontrado</h3>
                            }
                            </InfoRow>
                           

                                </Geral>

            <Modal data={modalData} closed={closedModal} setClosedOfParent={setClosedOfParent} />
        </div>
    )
}

export default DomainSection;