import React from 'react';
import { Container, Button } from '../../globalStyles';
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    Heading,
    SubTitle,
    BottomLine,
    CardWrapper,
    Card,
    TextColumn,
    DivText,
    HeroImage,
    Hero,
    DivImage, 
    InfoParagraph,
    DivTextAbout
} from './AboutSection.elements';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SchoolIcon from '@material-ui/icons/School';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { Parallax, Background } from 'react-parallax';
import { Info } from '@material-ui/icons';
import eventImage_1 from '../../img/jovem_dikota.jpeg';
import eventImage_2 from '../../img/sr_livro.jpeg';
import eventImage_3 from '../../img/bannerSobre.png';

const AboutSection = ({
    primary, 
    lightBg ,
    lightTopLine, 
    lightTextDesc, 
    buttonLabel, 
    description, 
    headLine, 
    lightText, 
    topLine,
    img
}) => {
    return (
        <>
            {/** style={{backgroundColor:'#ececec'}}*/}
            <Hero style={{backgroundImage:`url("${eventImage_3}")`}}>
               <div style={{width:"100%",height:"65vh",backgroundColor:"rgba(0,0,0,.5)"}}>

               </div>
            </Hero>
                <Heading>
                    SOBRE A INICIATIVA  
                </Heading>
                <div id="about">
             <DivTextAbout>
                    A plataforma Dikota_E6.0 é uma iniciativa da Primeira Dama da República de Angola, 
                    concebida para dar resposta aos anseios de um grupo da Sociedade Civil.
                    <br/><br/>
                    Na sua materialização, irá mobilizar e sensibilizar a sociedade em geral, em particular 
                    os mais jovens, os líderes, os formadores de opinião e decisores, a considerarem o valor 
                    e a importância do aprendizado de vida dos cidadãos com mais de 60 anos.
                    <br/><br/>
                    Tem como propósito sensibilizar a sociedade a cultivar a solidariedade entre gerações; 
                    promover a educação e formação ao longo da vida; a estabilidade emocional das gerações 
                    seniores, por meio da criação de programas e estratégias promotoras de “uma sociedade 
                    para todas as idades”.
                </DivTextAbout>
            <InfoSec lightBg={lightBg} >
                    <DivImage>
                        <HeroImage src={eventImage_1}></HeroImage>
                        <HeroImage style={{marginLeft:"40%", marginTop:"-60px"}} src={eventImage_2}></HeroImage>
                   </DivImage>
                <InfoRow >
                        <InfoParagraph>
                            <TextWrapper>
                                <TextColumn >
                                    <DivText>
                                        <br/><br/>
                                        <br/>
                                        <div style={{color: '#e0921c',fontSize:"1.3em", textAlign:"center"}}>Objectivos</div>
                                        <br/>
                                        <p style={{lineHeight: '32px'}} >  Os objectivos da Plataforma assentam no princípio segundo o qual o envelhecimento 
                                        activo e saudável se materializa pela participação e estabilidade emocional das gerações 
                                        seniores, sendo por isso, necessária a implementação de programas transversais e de 
                                        estratégias multidisciplinares promotoras de “uma sociedade para todas as idades”.
                                        </p>
                                        <br/>
                                        <div style={{color: '#FBB03B', fontSize:"1.3em",textAlign:"center"}}>Objectivos Gerais</div>
                                        <br/>
                                        <ol style={{listStyle:"inherit", marginLeft: "20px", lineHeight: '32px'}}>
                                            <li> Valorizar e captar experiências, catalogando e reconhecendo as suas valências;</li>
                                            <li> Sensibilizar a sociedade para cultivar a solidariedade entre gerações e o envelhecimento activo;</li>
                                            <li> Promover a educação e formação ao longo da vida;</li>
                                            <li> Sensibilizar as gerações seniores para o exercício da cidadania e protecção contra todas as formas de discriminação social, e divulgação de informação relativa a leis, normas, direitos e medidas relacionadas com o tema. </li>
                                            <li> Promover a educação e formação ao longo da vida;</li>

                                        </ol>
                                           
                                        <br/>
                                        <div style={{color: '#FBB03B', fontSize:"1.3em",textAlign:"center"}}>Objectivos Específicos</div>
                                        <br/>
                                        <ol style={{listStyle:"inherit", marginLeft: "20px", lineHeight: '32px'}}>
                                            <li> Promover a cooperação entre os sectores público e privado para o desenvolvimento de programas que assegurem a inclusão das gerações seniores; </li>
                                            
                                            <li> Incentivar a mobilização de quadros seniores em actividades de tutoria/mentoria e integração profissional de jovens no mercado de trabalho; </li>
                                            
                                            <li> Incentivar a realização de actividades que garantam o auto rendimento com recurso à prática da consultoria e freelancing; </li>
                                            
                                            <li> Reforçar a necessidade de se estabelecerem mecanismos de transferência de conhecimento intergeracional;</li>
                                            
                                            <li> Sensibilizar, em particular a classe empregadora, para melhor gestão do processo de reforma e aproveitamento das capacidades 6.0</li>
                                        </ol>
                                    </DivText>
                                </TextColumn>
                            </TextWrapper>
                        </InfoParagraph>
                    </InfoRow>
            </InfoSec>
            <InfoColumn >
                            <CardWrapper>
                                <Card>
                                    <VisibilityIcon style={{ fontSize:"3em", color: '#e0921c'}}/>
                                    <SubTitle lightTextDesc={lightTextDesc} orange>
                                        VISÃO
                                    </SubTitle>
                                    <BottomLine>
                                        Tornar Angola numa sociedade mais inclusiva, onde as gerações seniores, 
                                        independentemente do grau académico, nas dimensões sociais, económicas e 
                                        culturais contribuam, em pé de igualdade, com outras gerações, no atingir 
                                        de elevados níveis de saúde e bem-estar;
                                    </BottomLine>
                                    
                                </Card>
                                <Card>
                                    <SchoolIcon style={{fontSize:"3em", color: '#e0921c'}}/>
                                    <SubTitle lightTextDesc={lightTextDesc} orange>
                                        MISSÃO
                                    </SubTitle>
                                    <BottomLine>
                                        Identificar e mobilizar experiências, mediante a promoção de programas e 
                                        actividades que visem a participação deste grupo, no desenvolvimento económico, 
                                        na consolidação da acção social, na valorização da cultura angolana e na 
                                        preservação de valores da família, como a educação moral e cívica, a infância 
                                        saudável e activa, e a organização da comunidade à escala local.
                                    </BottomLine>
                                    
                                </Card>
                                <Card>
                                    <LabelImportantIcon style={{fontSize:"3em", color: '#e0921c'}}/>
                                    <SubTitle lightTextDesc={lightTextDesc} orange>
                                        VALORES
                                    </SubTitle>
                                    <BottomLine>
                                        Independência, participação, autorrealização, assistência e dignidade. Assume 
                                        os valores que Angola, como membro da Organização das Nações Unidas, sufraga 
                                        para as pessoas na terceira idade.
                                    </BottomLine>
                                    
                                </Card>
                            </CardWrapper>
                        </InfoColumn>
        </div>
        </>
    )
}

export default AboutSection;