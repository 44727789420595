import React,{ useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import GlobalStyle from './globalStyles';
import { Footer } from './components';
import Home from './pages/HomePage/Home';
import Event from './pages/EventPage/Event';
import Notice from './pages/DetailsNoticePage/Notice';
import About from './pages/AboutPage/About';
import Project from './pages/ProjectPage/Project';
//import Footer from './components';
 
function App() {

  return (
    <Router >
      <GlobalStyle />
      <Switch >
        <Route path="/" exact component={()=><Home />} />
        <Route path="/sobre" component={ ()=><About />} />
        <Route path="/projectos" component={()=><Project /> } />
        <Route path="/noticias" component={()=><Event /> } />
        <Route path="/noticia/:id" component={()=><Notice /> } />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
