import styled from 'styled-components';

export const InfoSec = styled.div`
    color: #E4992A;
    margin-bottom: 100px;
    background: #fff;
    width: 100%;
`;

export const InfoRow = styled.div`
    display: flex;
    margin: -15px -15px -15px -15px;
    height: 65vh;
    @media screen and (max-width: 1024px){
        height: 40vh;
    }
    @media screen and (max-width: 960px){
        margin: 0px;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        flex-direction: column-reverse;
        height: 90%;
    }
    justify-content: space-between;
    flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
`;

export const InfoColumn = styled.div`
    margin: ${({ ImageColumn }) => (ImageColumn ? '0px' : '100px 15px 85px 15px')};
    max-width: 50%;
    flex-basis: 50%;
    @media screen and (max-width: 1024px){
        width: 100px;
    }
    @media screen and (max-width: 960px){
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        margin: ${({ ImageColumn }) => (ImageColumn ? '0px' : '60px 0px 0px 0px')};
    }
`;

export const TextWrapper = styled.div`
    max-width: 580px;
    margin-left: 15%;
    padding-top: 50px;
    padding-bottom: 50px;

    @media screen and (max-width: 960px){
        padding-bottom: 0px;
        max-width: 100%;
        margin: 0px 22px;
    }
`;

export const TopLine = styled.div`
    color: #606060;
    font-size: 10px;
    font-weight: 400;
    margin: 16px 16px;
    font-style:italic;
`;

export const Heading = styled.h1`
    color: #E4992A;
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 40px;
    max-width: 380px;

    @media screen and (max-width: 376px){
        font-size: 35px;
    }
`;

export const SubTitle = styled.p`
    max-width: 380px;
    margin: 28px 0px;
    font-size: 18px;
    color:  #fcb03b;
    font-weight: 600;

`;

export const ImgWrapper = styled.div`
    width: 100%;
    height: 65vh;
    
`;

export const Div = styled.div`
    width: 20%;
    position: absolute;
    background-image: linear-gradient(-90deg, rgba(255,255,255,0), rgba(255,255,255,1));
    z-index: 1;

    @media screen and (max-width: 1024px){
        height: 40vh;
    }
    @media (max-width: 768px){
        height: 65vh;
    }
`;