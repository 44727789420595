import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

import { Container, Button } from '../../globalStyles';
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    Heading,
    SubTitle,
    BottomLine,
    CardWrapper,
    Card,
    TextColumn,
    DivText,
    HeroImage,
    Hero,
    DivImage, 
    InfoParagraph,
    DivTextAbout,
    MiddleLine
} from './DetailsNotice.elements';
import twotoneDateRange from '@iconify-icons/ic/twotone-date-range';
import baselineLocationOn from '@iconify/icons-ic/baseline-location-on';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SchoolIcon from '@material-ui/icons/School';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import eventImage_2 from '../../img/bannerNotice.jpg';
import { InlineIcon } from '@iconify/react';
import api from '../../services/api'
const DetailsNotice = ({
    primary, 
    lightBg ,
    lightTopLine, 
    lightTextDesc, 
    buttonLabel, 
    description, 
    headLine, 
    lightText, 
    topLine,
    img
}) => {
    const { id } = useParams();
    const [news, setNews] = useState({});
    useEffect(()=>{
        api.get(`noticias/${id}`).then((res)=>{
            console.log("res:", res.data)
            setNews(res.data)
        })
    },[])
    return (
        <>
            <Hero style={{backgroundImage:`url("${eventImage_2}")`, backgroundRepeat:"no-repeat", backgroundSize:"100% 100%"}}>
               
            </Hero>
            <div id="about" style={{margin:"0px 0px 100px 0px"}}>
                <Heading>{news.titulo}</Heading>
                <DivTextAbout>
                <div dangerouslySetInnerHTML={{
    __html:news.descricao
  }}></div>
                    <div style={{margin:'20px 0px 0px 0px'}}>
                        <MiddleLine>
                            <InlineIcon icon={twotoneDateRange} style={{color: '#e4992a', fontSize: '13px' }} />
                            Data de Publicação: {news.created_at}
                        </MiddleLine>
                    </div>
                </DivTextAbout>
            </div>
        </>
    )
}

export default DetailsNotice;