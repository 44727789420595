import React,{useEffect, useState} from 'react';
import { Container } from '../../globalStyles';
import {
    InfoSec, 
    InfoRow, 
    InfoColumn, 
    TextWrapper,
    TextWrapper2, 
    TopLine,
    Heading,
    SubTitle,
    Input,
    Button,
    NavLinks
} from './Footer.elements';
import { Link } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import api from '../../services/api'
const Footer = ({
    primary, 
    lightBg , 
    imgStart, 
    lightTopLine, 
    lightTextDesc, 
    buttonLabel, 
    description, 
    headLine, 
    lightText, 
    topLine,
    img
}) => {
    const [data, setData] = useState({})
    useEffect(()=>{
        api.get(`/rodape/1`)
          .then((res) => {
              console.log("response:", res.data)
            setData(res.data)
           
          });
      },[])
    return(
        <>
            <InfoSec lightBg={lightBg} >
                <Container style={{marginLeft: '7.3%'}}>
                    <InfoRow imgStart={imgStart}>
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText} >
                                    CONTACTO
                                </Heading>
                                <SubTitle >
                                    Telefone: {data.phone}
                                </SubTitle>
                                <SubTitle >
                                    Email: {data.email}
                                </SubTitle>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText} >
                                    ENDEREÇO
                                </Heading>
                                <SubTitle >
                                    {data.address}
                                </SubTitle>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText} >
                                    TORNA-SE MEMBRO
                                </Heading>
                                <SubTitle >
                                    <NavLinks href="https://dikota.ao/backoffice/login" border>
                                        <span style={{borderRight: '2px solid #FFF', paddingRight: '10px'}}>Login</span>
                                    </NavLinks>
                                    <NavLinks style={{paddingLeft: '10px'}} href="https://dikota.ao/backoffice/register">
                                        Inscrever
                                    </NavLinks>
                                </SubTitle>
                            </TextWrapper>
                        </InfoColumn>
                    </InfoRow>
                    <InfoRow imgStart={imgStart} style={{marginTop: '20px'}}>
                        <InfoColumn>
                            <TextWrapper>
                                <SubTitle style={{marginTop: '10px', display:'flex'}}>
                                    <p style={{marginRight: '5px'}}>Siga-nos</p>
                                    <a href="https://www.facebook.com/" target="_blank" style={{color: "#fff"}}><FacebookIcon /></a>
                                    <a href="https://www.instagram.com/dikota_e6.0/?igshid=1szqgewbevvt2" target="_blank" style={{color: "#fff"}}><InstagramIcon /></a>
                                    <a href="https://www.linkedin.com/" target="_blank" style={{color: "#fff"}}><LinkedInIcon /></a>
                                </SubTitle>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <TextWrapper>
                                <SubTitle style={{marginTop: '10px'}}>
                                    DIKOTA_6.0 2020 | Todos os Direitos reservados
                                </SubTitle>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn>
                            <TextWrapper>
                                <SubTitle style={{marginTop: '10px'}}>
                                    Desenvolvido pelo: INFOSI
                                </SubTitle>
                            </TextWrapper>
                        </InfoColumn>
                    </InfoRow>
                </Container>
            </InfoSec>
        </>
    )
}

export default Footer;