import React, { useState } from 'react';
import styled from 'styled-components';
import {useHistory, Link} from 'react-router-dom';
import {slide as Menu} from 'react-burger-menu';
import AnchorLink from 'react-anchor-link-smooth-scroll';


export const NavLinks = styled(AnchorLink)`
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: ${({border}) => (border ? '0.5rem 0': '0.5rem')};

    @media screen and (max-width: 960px){
        text-align: left;
        padding: 0.5rem;
        width: 100%;
        display: table;

        &:hover{
            color: #4b59f7;
            transition: all 0.3s ease;
        }
  
`;


export const ItemLink = styled(Link)`
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: ${({border}) => (border ? '0.5rem 0': '0.5rem')};

    @media screen and (max-width: 960px){
        text-align: left;
        padding: 0.5rem;
        width: 100%;
        display: table;

        &:hover{
            color: #4b59f7;
            transition: all 0.3s ease;
        }
    }
`;

const Links = styled.a`
    color: #E4992A;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: ${({border}) => (border ? '0.5rem 0': '0.5rem')};

    @media screen and (max-width: 960px){
        text-align: left;
        padding: 0.5rem;
        padding-left: 0px;
        width: 100%;
        display: table;

        &:hover{
            color: #4b59f7;
            transition: all 0.3s ease;
        }
    }
`;

const StyledBurger = styled.div`

    display: block;
    z-index: 10;
    .page-wrap{
        overflow: auto;
       
    }
        /* Individual item */
    .bm-item {
        display: inline-block;
        padding: 20px;
        /* Our sidebar item styling */
        text-decoration: none;
        margin-bottom: 10px;
        color: white;
        transition: color 0.2s;
    }

    /* Change color on hover */
    .bm-item:hover {
        color: #ccc;
    }

    /* The rest copied directly from react-burger-menu docs */

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: relative;
        width: 26px;
        height: 20px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #E4992A;
        border-radius: 80px;
    }

    .bm-cross-button {
        margin: 20px 10px 0px 0px;
        height: 56px;
        width: 50px;
        
        @media screen and (max-width: 960px){
            position: fixed !important;
        }
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #fff;
    }

    /* General sidebar styles */
    .bm-menu {
        background-color: #E4992A;
        opacity: 1;
        color: white;
        padding: 2.6em 1.5em 0;
        font-size: 1.15em;
        width: 100%;
        position: absolute;
        top: 0px;
        text-align:justify; 
        height: 100%;
        @media (max-width: 980px) {
            position: fixed;
            top: 0px;
        }
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: white;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
}
.bm-menu-wrap{
    top:0; !important;
    height:120vh !important;
    width: 21.8% !important;
}

@media screen and (max-width: 768px) {
    .bm-menu-wrap{
        top:0; !important;
        width: 100% !important;
    }
  }
`;

const Burger = (props) => {
    const history = useHistory();
   

    return (
        <StyledBurger>
            <Menu isOpen={props.open} class="Menu" right style={{textAlign: "left!important"}}>
                <ItemLink to="/" > INÍCIO </ItemLink>
                <ItemLink to="/sobre" > SOBRE </ItemLink>
                <ItemLink to="/projectos" > PROJECTOS </ItemLink>
                <ItemLink to="/noticias" > NOTÍCIAS </ItemLink>
                <Links href="https://dikota.ao/backoffice"> LOGIN </Links>
                <Links href="https://dikota.ao/backoffice/register"> INSCREVER </Links>
            </Menu>
            
        </StyledBurger>
    )
}

export default Burger;