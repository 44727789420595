import styled from 'styled-components';

export const Geral = styled.div`
    margin: 30px 0px 100px 0px;
`;


export const InfoRow = styled.div`
display: flex;
width: 100%;
flex-wrap: wrap;
text-align:justify !important;
padding: 0px !important;
margin: 0px !important;

@media screen and (max-width: 782px){
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-center:center;
    justify-content: center;
    flex-direction:column;
}
justify-content:center;
align-items: center;
  
`;

export const InfoColumn = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
justify-content: center;
@media screen and (max-width: 782px){
    margin: 0px;
}
`;

export const TextWrapper = styled.div`
max-width: 100%;
padding: 50px 0px;
@media screen and (max-width: 782px){
    padding-bottom: 55px;
}
`;

export const Heading = styled.h1`
    font-size: 34px;
    font-weight: bold;
    color: #E4992A;
    font-weight: 400;
    border-bottom: 1px solid #e0921c;
    width: 100%;
    margin: 50px auto 10px auto;
    padding-bottom: 15px;
    @media screen and (max-width: 1041px){
        margin: 30px auto 10px auto;
        text-align: center;
        font-size: 20px;
    }
    @media screen and (max-width: 955px){
        max-width: 70%;
        margin: auto auto 10px auto;
        text-align: center;
    }
    @media screen and (max-width: 782px){
        font-size: 24px;
        margin-top: 30px;
        width: 100%;
    }
`;

export const SubTitle = styled.p`
    padding: 10px 0px;
    font-size: 24px;
    font-weight: bolder;
    color: ${({orange}) => (orange ? '#E4992A': '#B3ACA2' )};
    @media screen and (max-width: 1158px){
        font-size: 14px;
        padding: 0px;
    }
`;

export const  BottomLine = styled.div`
padding: 10px 0px;
color: #838383;
font-size: 15px;
font-weight: medium;
@media screen and (max-width: 1158px){
    font-size: 10px;
    padding-top: 5px;
}
`;

export const  MiddleLine = styled.div`
    color: #E4992A;
    padding: 10px 0px;
    font-size: 16px;
    text-align:justify;
    line-height:23px;
    @media screen and (max-width: 1158px){
        font-size: 10px;
        padding: 0px;
    }
    @media screen and (max-width: 961px){
        font-size: 10px;
        padding: 0px;
    }
`;



export const Card = styled.div`
text-align: left !important;
width: 40vw;
min-height: 320px;
margin: 10px 18px;
background: #FFFFFF;
box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
border-radius: 10px;
vertical-align: middle;
display: flex;

@media screen and (min-width: 1591px){
    height: 180px;
}

@media screen and (max-width: 1158px){
    height: 180px;
}


@media screen and (max-width: 1398px){
    width: 40vw;
    min-height: 250px;
}

@media screen and (max-width: 1041px){
    min-height: 200px;
}

@media screen and (max-width: 955px){
    height: 15%;
}

@media screen and (max-width: 768px){
    display: flex;
    flex-direction:column;
    width: 50%;
    margin-bottom:120px;
    justify-content:center;
    align-items:center;
    height:60vh;
}
@media screen and (max-width: 426px){
    display: flex;
    flex-direction:column;
    width: 70%;
    margin-bottom:120px;
    justify-content:center;
    align-items:center;
}
@media screen and (max-width: 320px){
    display: flex;
    flex-direction:column;
    width: 80%;
    margin-bottom:120px;
    justify-content:center;
    align-items:center;
}

`;

export const CardImage = styled.img`
    width: 38%;
    border-radius: 10px 0px 0px 10px;
    @media screen and (max-width: 1158px){
        width: 180px;
    }
    @media screen and (max-width:782px){
        width: 100%;
        height: 300px;
        border-radius: 0px;
    }
    @media screen and (max-width:568px){
        width: 100%;
        height: 250px;
        border-radius: 0px;
    }
`;
export const CardBody = styled.div`
    width: 100%;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 0px 10px 50px 0px rgba(0, 92, 141, 0);
    border-radius: 0px 10px 10px 0px;
    @media screen and (max-width: 1158px){
        width: 100%;
        height: 180px;
        margin:0px;
    }
`;

export const Div = styled.div`
    padding:20px 0px 0px 0px;

    &&:hover{
        text-decoration: underline;
    }

`;

export const P = styled.p`
    @media screen and (max-width: 1158px){
        font-size: 12px;
    }
    @media screen and (max-width: 961px){
        font-size: 11px;
    }
`;