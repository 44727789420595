import React,{useState, useEffect} from 'react';
import { Container } from '../../globalStyles';
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    Heading,
    SubTitle,
    MiddleLine,
    BottomLine,
    CardWrapper,
    Div,
    P,
    Card,
    CardImage,
    CardBody,
    Button
} from './EventSection.elements';
import { 
    ItemLink
} from '../NavBar/Navbar.elements';
import { InlineIcon } from '@iconify/react';
import twotoneDateRange from '@iconify-icons/ic/twotone-date-range';
import baselineLocationOn from '@iconify/icons-ic/baseline-location-on';
import eventImage_1 from '../../img/Notice.png';
import GetAppIcon from '@material-ui/icons/GetApp';
import api from '../../services/api';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Spinner } from "@chakra-ui/react"

const EventSection = ({
    primary, 
    lightBg ,
    lightTopLine, 
    lightTextDesc, 
    headLine, 
    lightText,
}) => {
    const [news, setNews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        api.get("/noticias")
        .then((res)=>{
            setNews(res.data)
            setIsLoading(false)
        })
    },[])
    return (
        <div id="event">
            <InfoSec lightBg={lightBg} >
               
                <Container>
                    <InfoRow >
                        <InfoColumn>
                            <TextWrapper>
                                <Heading lightText={lightText} >
                                    NOTÍCIAS
                                </Heading>
                            </TextWrapper>
                        </InfoColumn>
                        <InfoColumn >
                        {
                    news.length > 0 
                    ?
                    news.map((elem, index)=>(
                        <CardWrapper>
                                <Card>
                                    <CardImage src={`https://dikota.ao/backend/api/u/${elem.imagem}`} />
                                    <CardBody>
                                        <SubTitle lightTextDesc={lightTextDesc} orange>
                                            {elem.titulo}
                                        </SubTitle>
                                        <MiddleLine><InlineIcon icon={twotoneDateRange} style={{color: '#e4992a', fontSize: '13px' }} />Data de Publicação: {elem.created_at}</MiddleLine>
                                        <BottomLine>{elem.resumo}</BottomLine>
                                        <Div>
                                        <ItemLink to={`/noticia/${elem.id}`} >
                                            <a style={{color:'#E4992A', cursor:'pointer', display:'flex', textDecoration:"none"}}>
                                            <VisibilityIcon style={{color:'#E4992A', marginRight:'5px'}} />
                                            <P>Vizualizar</P>
                                            </a>
                                        </ItemLink>
                                        </Div>
                                    </CardBody>
                                    
                                </Card>
                            </CardWrapper>
                    ))
                    : isLoading
                    ?
                    <Spinner style={{width:'70px', height:'70px'}} color={'#E4992A'}/>
                    :
                    <h3>Nenhum projecto encontrado</h3>
                }
                          
                        </InfoColumn>
                    </InfoRow>
                    
                    {
                        /* <ReactPaginate 
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={3}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            /> 
                        */
                    }
                </Container>
            </InfoSec>
        </div>
    )
}

export default EventSection;