import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

import {
  ModalElement,
  ModalContent,
  ModalHeader,
  CloseButton,
  Title,
  Text
} from './Modal.elements';

const Modal = (props) => {  
  const [closed, setClosed] = useState(props.closed);

  useEffect(() => {
    if (!props.closed) HandleOpenModal();
    else handleCloseModal();
  });

  function HandleOpenModal() {
    setClosed(false)
  }

  function handleCloseModal() {
    setClosed(true);

    props.setClosedOfParent();
  }

  window.onclick = function (event) { 
    if (event.target.id === "modal-element") {
      setClosed(true);
  
      props.setClosedOfParent();
    }
  }

  window.onscroll = function () {
    if (!closed) {
      setClosed(true);
  
      props.setClosedOfParent();
    }
  }

  return (
    <ModalElement id="modal-element" closed={closed}>
      <ModalContent>
        <ModalHeader>
          <CloseButton>
            <FaTimes 
              style={{color: "#E4992A"}} 
              onClick={ handleCloseModal }
            />
          </CloseButton>
        </ModalHeader>
        <Title>{props.data.title}</Title>
        <Text>{props.data.text}</Text>
      </ModalContent>
    </ModalElement>
  );
}

export default Modal;