import styled from 'styled-components';

export const InfoSec = styled.div`
    color: #fff;
    padding: 90px 0;
    background: #FAFAFA;
`;

export const InfoRow = styled.div`
    display: flex;
    margin: 0 -15px -15px -40px;
    @media screen and (max-width: 768px){
        margin: 0px;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse': 'row')};
`;

export const InfoColumn = styled.div`
    margin-bottom: 15px;
    margin-right: 15px;
    margin-left: 15px;
    flex: 1;
    max-width: ${({ImageColumn}) => (ImageColumn ? '45%': '65%')};
    flex-basis: ${({ImageColumn}) => (ImageColumn ? '45%': '65%')};
    @media screen and (max-width: 768px){
        margin: 0px;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        flex-direction: ${({ImageColumn}) => (ImageColumn ? 'column': 'row')};
    }
`;

export const TextWrapper = styled.div`
    margin-left: 2%;
    max-width: 840px;
    padding: 0px 50px;
    @media screen and (max-width: 768px){
        padding: 0px 0px 50px 0px;
        margin: 0px;
        max-width: 100%;
    }
`;

export const  TopLine = styled.div`
    color: #E4992A;
    font-weight: 900;
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: 6px;
    margin-bottom: 16px;
`;

export const Heading = styled.h1`
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 2px;
    color: #838383;
`;

export const Form = styled.div`
    max-width: 520px;
    display: flex;
    justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
    flex-direction: column;
`;

export const Input = styled.input`
    width: 100%;
    min-height: ${({ area }) => ( area ? '180px': '30px')};
    box-sizing: border-box;
    transition: 0.3s;
    padding: 14px 0px 14px 50px;
    margin-bottom: 10px;
    border: 1px solid #F4F4ED;

    &:focus {
        border: 1px solid orange;
        box-shadow: 0 0 8px 0 orange;
    }
`;


export const Button = styled.button`
    width: 50%;
    border-radius: 4px;
    background: #E4992A;
    white-space: nowrap;
    padding: ${({big}) => (big ? '12px 64px': '10px 12px')};
    margin: 14px 0px;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    outline: none;
    border: 1.8px solid #E4992A;
    cursor: pointer;

    &:hover {
        transition: all 0.3s ease-in-out;
        background: #fff;
        color: #E4992A;
    }

    @media screen and (max-width: 960px){
        width: 100%;
    }
`;