import React, { useState, useEffect } from 'react'
import {useHistory} from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import Burger from './Burger';
import './Burger.css';

import { 
        Nav, 
        NavbarContainer,
        NavLogo,
        Links,
        NavMenu,
        NavItem,
        NavLinks,
        NavBurger,
        ItemLink
} from './Navbar.elements';
import img from '../../img/logo.svg';


const Navbar = (props) => {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const history = useHistory();
    const [refer, setRefer] = useState();
    const [active, setActive] = useState("item-one");
    const [open, setOpen] = useState(false);

    const showButton = () => {
        if(window.innerWidth <= 960 ){
            setButton(false);
        } else{
            setButton(true);
        }
    }

    

    useEffect(() =>{
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    const handleClick = () => setClick(!click);

    return (


        <>
            <IconContext.Provider value={{ color: "#fff" }} >
                <Nav style={{position:"absolute",zIndex:"100000"}}>
                    <NavbarContainer  style={{marginLeft: '7.5%'}}>
                        <NavLogo to="/">
                            <img src={img} width="180px" style={{marginRight:'20px'}} />
                        </NavLogo>
                        
                        <NavMenu onClick={handleClick} click={click} >
                            <NavItem style={ props.active == "home" ? {borderBottom:'2px solid  #E4992A'} : {borderBottom:'none'}} onClick={() =>{ setActive("item-one");  setOpen(false)}}>
                                <ItemLink to="/" >
                                        INÍCIO
                                </ItemLink>
                            </NavItem>
                            <NavItem style={ props.active == "sobre" ? {borderBottom:'2px solid  #E4992A'} : {borderBottom:'none'}} onClick={() =>{ setActive("item-two") ;  setOpen(false)}}>
                                <ItemLink to="/sobre" >
                                        SOBRE
                                </ItemLink>
                            </NavItem>
                            <NavItem style={ props.active == "projectos" ? {borderBottom:'2px solid  #E4992A'} : {borderBottom:'none'}} onClick={() => {setActive("item-three");  setOpen(false)}}>
                                <ItemLink to="/projectos" >
                                        PROJECTOS
                                </ItemLink>
                            </NavItem>
                            <NavItem style={ props.active == "noticias" ? {borderBottom:'2px solid  #E4992A'} : {borderBottom:'none'}} onClick={() =>{ setActive("item-four");  setOpen(false)}}>
                                <ItemLink to="/noticias" >
                                        NOTÍCIAS
                                </ItemLink>
                            </NavItem>
                        </NavMenu>
                        <NavMenu onClick={handleClick} click={click}>
                            <NavItem >
                                <Links href="https://dikota.ao/backoffice/login" border>
                                    <span style={{marginLeft:'10px', borderRight: '2px solid #E4992A', paddingRight: '10px', fontWeight: `400`}}>Login</span>
                                </Links>
                            </NavItem>
                            <NavItem>
                                <Links href="https://dikota.ao/backoffice/register">
                                    Inscrever
                                </Links>
                            </NavItem>
                            <Burger pageWrapId={"page-wrap"} outerContainerId={"App"} open={open}/>
                        </NavMenu>
                        <NavBurger>
                            <Burger pageWrapId={"page-wrap"} outerContainerId={"App"} open={open} />
                        </NavBurger>
                    </NavbarContainer>
                </Nav>
            </IconContext.Provider> 
           
        </>
    )
}

export default Navbar
