import React from 'react';
import { 
    homeObjFour 
} from '../HomePage/Data';
import { 
    AboutSection
} from '../../components';
import Navbar from "../../components/NavBar/Navbar"
const Event = () => {
    return (
        <>
            <Navbar active = "sobre"/>
            {
                window.scrollTo(0, 0)
            }

            <AboutSection {...homeObjFour} />
        </>
    )
}

export default Event
