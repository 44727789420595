import React,{ useState, useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import 'react-slideshow-image/dist/styles.css';
import img1 from '../../img/imagem2.png';
import img2 from '../../img/imagem4.png';
import img3 from '../../img/img.png';
import api from '../../services/api'
const Div = styled.div`
    width: 100%;
    height: 65vh;    

    .each-slide > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
        height: 65vh;  
    }
    span{
        color: white;
        font-size: 220%;
        width: 65%;
        text-align: center;
    }
    .img{
      width: 100%;
      background-size:cover;
      background-position: center center;
      background-repeat: no-repeat;
      filter: brightness(85%);
    }
    .icon{
      color: white;
    }
    .icon:hover{
      color: #ccc;
      cursor: pointer;
    }
    @media (max-width: 1441px){
      .each-slide > div {
        height: 65vh;  
      }
    }
    @media (max-width: 1025px){
      .each-slide > div {
        height: 65vh;  
      }
    }
    @media (max-width: 768px){
      .each-slide span{
        font-size: 120%;
      }
      .each-slide > div {
        height: 65vh;  
      }
    }
`


const properties = {
  indicators: true,
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  prevArrow: <ArrowBackIosIcon className="icon" style={{width: "30px", marginRight: "-30px", background: 'none', display: 'none'}}/>,
  nextArrow: <ArrowForwardIosIcon className="icon" style={{width: "30px", marginLeft: "-30px", background: 'none', display: 'none'}}/>
};



const SlideShow = (props) => {
  const [banners, setBanners] = useState([]);

  useEffect(()=>{
    api.get('/banners').then((res)=>{
        setBanners(res.data)
    })
  },[])

    return (
    <Div>
      <div>
        <Slide {...properties}>
          {
            banners.map((elem, index)=>(
              <div className="each-slide" {...index == 0 ? `style={{backgroundImage: 'linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1))'}}` : ''}>
                <div className="img" style={{'backgroundImage': `url('https://dikota.ao/backend/api/u/${elem.imagem}')`}}>   
                </div>
              </div>
            ))
          }
          
        </Slide>
      </div>
    </Div>
    )
};

export default SlideShow;