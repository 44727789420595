import styled from 'styled-components';

export const InfoSec = styled.div`
    text-align: center;
    background-color: #FAFAFA;
    padding: 20px 0px 60px 0px;
    @media screen and (max-width: 768px){
        padding: 30px 0px;
        margin-top:3vh;
    }
`;

export const InfoRow = styled.div`
    display: flex;
    margin: 0 -15px -15px -15px;
    width: 100%;
    @media screen and (max-width: 768px){
        width: 100%;
        margin: 0px;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    align-items: center;
    flex-direction: column;
`;

export const InfoColumn = styled.div`
    margin-bottom: 15px;
    margin-right: 15px;
    margin-left: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 768px){
        margin: 0px;
    }
`;

export const TextWrapper = styled.div`
    width: 100%;
    padding: 50px;
    @media screen and (max-width: 768px){
        padding-bottom: 55px;
    }
`;

export const Heading = styled.h1`
    font-size: 34px;
    font-weight: bold;
    color: #E4992A;
    padding-bottom: 15px;
    font-weight: 400;
    border-bottom: 1px solid #e0921c;
    width: 15%;
    margin: 50px auto 10px auto;
    padding-bottom: 15px;
    @media screen and (max-width: 768px){
        font-size: 24px;
        width: 100%;
    }
`;

export const SubTitle = styled.p`
    padding: 10px 0px;
    font-size: 24px;
    font-weight: bolder;
    color: ${({orange}) => (orange ? '#E4992A': '#B3ACA2' )};
`;

export const  BottomLine = styled.div`
    padding: 10px 0px;
    color: #838383;
    font-size: 15px;
    font-weight: medium;
`;

export const  MiddleLine = styled.div`
    color: #E4992A;
    padding: 10px 0px;
    font-size: 13px;
`;

export const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const Card = styled.div`
    text-align: left !important;
    width: 370px;
    min-height: 480px;
    margin: 10px 18px;
    padding-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 10px 10px 50px 3px rgba(39, 92, 141, 0.1);
    border-radius: 10px;
    vertical-align: middle;
    display: inline-block;
    @media screen and (max-width: 768px){
        margin: 12px 0px;
    }

`;

export const CardImage = styled.img`
    width: 100%;
    min-height: 300px;
    max-height: 300px;
`;

export const CardBody = styled.div`
    padding: 10px 20px;
`;

export const Button = styled.button`
    border-radius: 4px;
    background: #E4992A;
    white-space: nowrap;
    padding: ${({big}) => (big ? '12px 64px': '7px 12px')};
    margin: ${({m}) => (m ? '14px 0px 22px 0px': '0px')};
    color: #fff;
    font-size: ${({fontBig}) => (fontBig ? '20px': '1.1rem')};
    font-weight: bold;
    outline: none;
    border: 1.8px solid #E4992A;
    cursor: pointer;
    margin-top:2vh;
    width: 100%;

    p{
        margin-top: 2px;
        font-size: 15.5px;
    }

    &:hover {
        transition: all 0.3s ease-in-out;
        background: #fff;
        color: #E4992A;
    }
    
    @media screen and (max-width: 768px){
        width:100%;
        height:5vh;
        font-size:2.5vw;
        text-align:center;
        margin-left:0;
        margin-top:2vh;
        padding:0px;
        p{
            margin-top: 3.5px;
            font-size: 15px;
        }
    }
    
`;

/*
export const A = styled.a`
    display: flex;
    text-decoration: none;
    color: white;
    &:hover {
        transition: all 0.3s ease-in-out;
        background: #fff;
        color: #E4992A;
    }
`;
*/
export const Div = styled.a`
    padding:20px 0px 0px 0px;
    text-decoration: none;

    &&:hover{
        text-decoration: underline;
    }

`;

export const P = styled.p`
    margin-top: 2px;

`;
