import styled from 'styled-components';

export const InfoSec = styled.div`
    color: #fff;
    text-align: center;
    background-color: #fff;
    padding: 20px 0px 60px 0px;
    @media screen and (max-width: 768px){
        padding: 30px 0px;
    }
`;

export const InfoRow = styled.div`
    display: flex;
    margin: 0 -15px -15px -15px;
    width: 100%;
    @media screen and (max-width: 768px){
        margin: 0px;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    align-items: center;
    flex-direction: column;
`;

export const InfoColumn = styled.div`
    margin-bottom: 10px;
    margin-right: 15px;
    margin-left: 15px;
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 768px){
        margin: 0px;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const TextWrapper = styled.div`
    width: 100%;
    padding-top: 0;
    padding: 50px;
    @media screen and (max-width: 768px){
        padding: 0px;
    }
`;

export const Heading = styled.h1`
    margin-bottom: 50px;
    font-size: 34px;
    font-weight: bold;
    color: #E4992A;
`;

export const SubTitle = styled.p`
    max-width: 74%;
    margin: auto auto 10px auto;
    font-size: 20px;
    font-weight: bolder;
    line-height: 1.7;
    text-align: ${({just}) => (just ? 'justify': 'center' )};
    color: ${({orange}) => (orange ? '#E4992A': '#838383' )};
    @media screen and (max-width: 768px){
        max-width: 100%;
        text-align: ${({just}) => (just ? 'justify': 'center' )};
    }
`;

export const TextColumn = styled.div`
    max-width: 100%;
    margin: auto auto 10px auto;
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;
    font-size: 18px;
    font-weight: bolder;
    line-height: 1.7;
    text-align: justify;
    color: #838383;
    @media screen and (max-width: 768px){
        max-width: 100%;
    }
`;

export const  BottomLine = styled.div`
    color: #838383;
    text-align: justify;
    font-size: 15px;
    margin-bottom: 16px;
`;

export const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const Card = styled.div`
    padding: 20px;
    width: 320px;
    min-height: 320px;
    margin: 10px 12px;
    vertical-align: middle;
    display: inline-block;

    @media screen and (max-width: 768px){
        width: 100%;
        max-width: 100%;
        margin: 12px 0px;
    }
`;