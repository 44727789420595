import styled from 'styled-components';
import { FaDonate } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Container } from '../../globalStyles';
import AnchorLink from 'react-anchor-link-smooth-scroll';

{/*@media (min-width: 640px){
 
    ...
     
    }
     
    @media (min-width: 768px){
     
    ...
     
    }
     
    @media (min-width: 1024px){
     
    ...
     
    }
     
    @media (min-width: 1200px){
     
    ...
     
    }*/}

export const Nav = styled.nav`
    box-shadow: 5px 5px 10px #ccc;
    width: 100%;
    background: #f5f5f5;
    height: 70px;
    display: flex;
    align-items: flex-start;
    font-size: 1.1rem;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 999;
    
`;

export const NavbarContainer = styled(Container)`
    margin-left: 65px;
    width: 82%;
    display: flex;
    justify-content: space-between;
    height: 70px;
    @media screen and (max-width: 960px) {
        width: 100%;
        margin-left: 0px;
    }

    ${Container}
`;

export const NavLogo = styled(Link)`
    color:#fff;
    justify-self: flex-start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
`;


export const MobileIcon = styled.div`
    display: none;
    
    @media screen and (max-width: 960px){
        display: block;
        position: absolute;
        top: -7px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 960px) {
        display: none;
    }
`;

export const NavBurger = styled.ul`
    display: none;
    list-style: none;
    @media screen and (max-width: 960px) {
        display: flex;
        margin-top: 23px;
    }
`;
 
export const NavItem = styled.li`
    height: 70px;
    border-bottom: 2px solid transparent;
    font-weight: 400;
    &:hover{
        border-bottom: 2px solid  #E4992A;
    }
   
    @media screen and (max-width: 960px) {
        width: 100%;

        &:hover{
            border: none;
        }

    }

    
`;

export const NavLinks = styled(AnchorLink)`
    color: #E4992A;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: ${({border}) => (border ? '0.5rem 0': '0.5rem 1rem')};
    height: 100%;
    font-weight: 400;
    @media screen and (max-width: 960px){
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover{
            color: #4b59f7;
            transition: all 0.3s ease;
        }
        
    }
`;


export const ItemLink = styled(Link)`
    color: #E4992A;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: ${({border}) => (border ? '0.5rem 0': '0.5rem 1rem')};
    height: 100%;
    font-weight: 400;
    
    @media screen and (max-width: 960px){
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover{
            color: #4b59f7;
            transition: all 0.3s ease;
        }
    }
`;

export const Links = styled.a`
    color: #E4992A;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: ${({border}) => (border ? '0.5rem 0': '0.5rem 1rem')};
    height: 100%;
    font-weight: 400;
    @media screen and (max-width: 960px){
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover{
            color: #4b59f7;
            transition: all 0.3s ease;
        }
    }
`;

export const NavItemBtn = styled.li`
    @media screen and (max-width: 960px){
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`;

export const NavBtnLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 12px;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
`;

