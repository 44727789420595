import styled from 'styled-components';

export const InfoSec = styled.div`
    margin-top:230px;
    color: #fff;
    padding: 0;
    background: #fff;
    width: 100%;
    @media screen and (max-width: 1024px){
        margin-top:340px;
    }
    @media screen and (max-width: 768px){
        margin-top:50px;
    }
    
`;

export const InfoRow = styled.div`
    max-width: 100%;
    display: flex;
    margin: 0 -15px -15px -40px;
    
    @media screen and (max-width: 1024px){
        width: 100%;
        margin: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }
    flex-direction: ${({imgStart}) => (imgStart ? 'row-reverse': 'row')};
`;

export const InfoColumn = styled.div`
    margin-bottom: 15px;
    margin-left: 30px;
    flex: 1;
    max-width: 60%;
    flex-basis: 50%;
    @media screen and (max-width: 768px){
        max-width: 100%;
        flex-basis: 100%;
        margin: 0px;
        display: flex;
        justify-content: center;
    }
`;

export const TextWrapper = styled.div`
    width: 90%;
    padding-top: 0;
    padding-bottom: 50px;
    margin-left: -50px;
    @media screen and (max-width: 1024px){
        padding-bottom: 55px;
        width: 600px;
        margin: 0px 22px 0px -50px;
    }
    @media screen and (max-width: 768px){
        margin: 0px 22px 0px 14px;
    }
`;

export const  TopLine = styled.div`
    color: #B3ACA2;
    font-size: 18px;
    font-weight: bold;
    line-height: 16px;
    margin-left: 8%;
    margin-top: 3%;
`;

export const Heading = styled.h1`
    margin-top: 20px;
    margin-bottom: 34px;
    font-size: 34px;
    /* font-weight: bold; */
    color: #fd9017;
    font-weight: 400;
    text-align: center;

    @media screen and (max-width: 1024px){
        font-size: 30px;
        text-align: center;
    }
`;

export const SubTitle = styled.p`
    margin-left: 8px;
    max-width: 100%;
    font-size:  1.3em;
    line-height:25px;
    white-space: pre-line;
    font-weight: 400;
    color: #fd9017;
    text-align: justify;
    font-style:italic;
    padding: 0px 21px;
    @media screen and (max-width: 1024px){
        font-size:  1em;
    }
`;

export const SubTitle1 = styled.p`
    margin-left: 8px;
    max-width: 100%;
    font-size:  16px;
    line-height:25px;
    font-weight: 400;
    color: #fd9017;
    text-align: justify;
    font-style:normal;
    padding: 0px 21px;
    @media screen and (max-width: 1024px){
        font-size: 20px;
    }
    @media screen and (max-width: 961px){
        font-size: 15px;
    }
    @media screen and (max-width: 320px){
        font-size: 14px;
    }
 
`;

export const IconBottom = styled.div`
    width: 100%;
    padding: 0px 0px 0px 90%;
    @media screen and (max-width: 1024px){
        padding: 0px 0px 0px 85%;
    }
    @media screen and (max-width: 1024px){
        padding: 0px 0px 0px 87%;
    }
    @media screen and (max-width: 425px){
        padding: 0px 0px 0px 80%;
    }
    @media screen and (max-width: 375px){
        padding: 0px 0px 0px 76%;
    }
`;

export const ImgWrapper = styled.div`
    max-width: 755px;
    
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1024px){
        max-width: 100%;
    }

`;

export const Img = styled.img`
    padding: 20px;
    border: 0;
    width: 85%;
    margin-left: 15%;
    vertical-align: middle;
    display: inline-block;
    height: 100%;

    @media screen and (max-width: 1024px){
        max-width: 100%;
        padding: 0px;
        margin-left: 0px;
    }
`;