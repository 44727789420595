import styled from 'styled-components';

export const InfoSec = styled.div`
    width:80%;
    margin-left:11%;
    color: #fff;
    text-align: center;
    background-color: #fff;
    padding: 20px 0px 60px 0px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    @media screen and (max-width: 768px){
        padding: 30px 0px;
        margin-top:10vh;
        margin-left:0;
        flex-direction:column;
    }
`;

export const DivImage = styled.div`
    margin-top:6%;
    width:24%;
    display:flex;
    flex-direction:column;
    @media screen and (max-width: 768px){
        width:60%;
        margin-top:-12%;
        justify-content:center;
        align-items:center;
    }

    @media screen and (max-width: 1024px){
        width:60%;
        margin-top:-12%;
        justify-content:center;
        align-items:center;
    }
`;


export const HeroImage = styled.img`
    width: 70%;
    height:37vh;
    border-radius:4px;
    box-shadow:2px 2px 2px rgba(0,0,0,.3);
    @media screen and (max-width: 1158px){
        width: 180px;
    }
    @media screen and (max-width:782px){
        width: 100%;
        height: 300px;
        border-radius: 0px;
    }
    @media screen and (max-width:568px){
        width: 100%;
        height: 250px;
        border-radius: 0px;
    }
`;
export const Hero = styled.div`
    width: 100%;
    height:65vh;
    @media screen and (max-width:425px){
        height: 360px;
    }
    @media screen and (max-width:375px){
        height: 300px;
    }
`;

export const titleHero = styled.span`
    color:black;
    font-size:70px;
`;

export const InfoRow = styled.div`
    display: flex;
    margin: 0 -15px -15px -15px;
    width: 75%;
    
    @media screen and (max-width: 768px){
        margin: 0px;
        max-width: 100%;
        flex-basis: 100%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    align-items: center;
    flex-direction: column;
`;

export const InfoColumn = styled.div`
    width: 100%;

    @media screen and (max-width: 768px){
        margin: 0px;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const InfoParagraph = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left:10%;
    @media screen and (max-width: 768px){
        margin-left:10%;
        max-width: 100%;
        flex-basis: 100%;
        display: flex;
        justify-content: center;
    }
`;

export const Heading = styled.h1`
    font-size: 34px;
    font-weight: bold;
    color: #E4992A;
    padding-bottom: 15px;
    font-weight: 400;
    border-bottom: 1px solid #e0921c;
    width: 44%;
    margin: 50px auto 10px auto;
    padding-bottom: 15px;
    @media screen and (max-width: 768px){
        font-size: 24px;
        width: 90%;
    }
`;

export const SubTitle = styled.p`
  
    font-size: 20px;
    line-height: 1.7;
    text-align: ${({just}) => (just ? 'justify': 'center' )};
    color: ${({orange}) => (orange ? '#e0921c': '#838383' )};
    font-weight: 500;
    @media screen and (max-width: 768px){
        max-width: 100%;
        text-align: ${({just}) => (just ? 'justify': 'center' )};
    }
`;

export const TextColumn = styled.div`
    max-width: 100%;
    display: flex;
    justify-content: center;

    font-size: 16px;
    text-align: justify;
    color: #838383;
    @media screen and (max-width: 768px){
        max-width: 100%;
        width: 100%;
        flex-direction: column;
        margin: 0px;
    }
`;
export const DivText = styled.div`
    width: 100%;
    color:gray;
    @media screen and (max-width: 768px){
        width: 90%;
        margin: 0px auto 30px auto;
    }
`;

export const DivTextAbout = styled.div`
    line-height: 20px;
    width: 100%;
    color:gray;
    width:80%;
    margin-left:11%;
    margin-top:5%;
    text-align:justify;
    @media screen and (max-width: 768px){
        width: 90%;
        margin-left:5%;
        margin-top:20%;

    }
`;

export const  BottomLine = styled.div`
    color: #838383;
    text-align: center;
    font-size: 15px;
    margin-bottom: 16px;
`;

export const CardWrapper = styled.div`
    display: flex;
    margin:1% auto 7% auto;
    max-width: 78%;
    justify-content: center;
    @media screen and (max-width: 768px){
        justify-content:center;
        flex-direction:column;
        align-items:center;
        margin-left:0;
    }
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 85%;
    padding: 50px 0px;
    

    @media screen and (max-width: 1441px){
        max-width: 84%;
    }

    @media screen and (max-width: 768px){
        padding: 0px;
        max-width: 100%;
    }
`;

export const Card = styled.div`
    padding: 40px 20px 40px 20px;
    width: 310px;
    margin: 3px 10px;
    background: #ececec;
    display:flex;
    flex-direction:column;

    align-content: space-between;
    align-items:center;
    border-radius: 5px;

    @media screen and (max-width: 768px){
        margin: 12px 0px;
    }
`;

export const  MiddleLine = styled.div`
    color: #E4992A;
    padding: 2px 0px;
    font-size: 16px;
`;