import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import cx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useBlogTextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import api from "../../services/api";
import MessageIcon from '@material-ui/icons/Message';
import Avatar from 'react-avatar';
import {
  Input,
  Button as Btn,
  TableNewRow,
  TableNewColumn,
  DivG,
  DivS,
  DivM
} from './SearchSection.elements';

import swal from 'sweetalert';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});



const useStylesCard = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: 'auto',
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: 900,
    marginLeft: 'auto',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: spacing(2),
    },
  },
  media: {
    width: '88%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: '48%',
    borderRadius: spacing(2),
    backgroundColor: '#fff',
    position: 'relative',
    [breakpoints.up('md')]: {
      width: '100%',
      marginLeft: spacing(-3),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'linear-gradient(147deg, #E4992A 0%, #E4992A 74%)',
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
}));





export const BlogCardDemo = React.memo(function BlogCard(props) {
  const styles = useStylesCard();
  const [mensagem, setMensagem] = useState("");
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");



  async function handleSubmit(event, id) {
    event.preventDefault();


    if (!mensagem || !nome || !email) {
      swal("Preencha todos os campos!", "Click no botão para tentar novamente!", "warning");

      return;
  }

    const data = new FormData();

    data.append("senior_id", id);
    data.append("mensagem", mensagem);
    data.append("nome", nome);
    data.append("email", email);


    await api.post("mensagens", data);

    swal("Mensagem enviada com sucesso!", "Click no botão para fechar!", "success");

    setMensagem("");
    setNome("");
    setEmail("");
  }

  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();
  const shadowStyles = useOverShadowStyles();
  return (
    <Card className={cx(styles.root, shadowStyles.root)}>
      <CardContent style={{ width:'95%', margin:'auto'}}>
        <form id="mensagem" onSubmit={(e) => handleSubmit(e, props.dikotas.id)}>

                <Input
                  type="text"
                  name="Nome Completo"
                  placeholder="Nome Completo"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Input
                  type="area"
                  area
                  placeholder="Mensagem ao Dikota"
                  name="mensagem"
                  value={mensagem}
                  onChange={(e) => setMensagem(e.target.value)}
                />

          <div>

          </div>

          <div style={{width:'100%', display:'flex', justifyContent:'right', alignItems:'rigth'}}>
            <Btn type="submit" form="mensagem" >Enviar</Btn>
          </div>
        </form>
      </CardContent>
    </Card>
  );
});
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const {
    button: buttonStyles,
    ...contentStyles
  } = useBlogTextInfoContentStyles();
  return (
    <React.Fragment>
      <TableNewRow align="center" className={classes.root} onClick={() => setOpen(!open)}>
        <TableNewColumn collapsedHeight="300px" style={{maxWidth:'38%', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems:'center' }}>
          <Avatar src={row.photo} name={row.name} size="12em" round={true} />
        </TableNewColumn>
        <TableNewColumn align="left" style={{Width:'90% !important', textAlign: 'left' }}>
          <div style={{textAlign:'left', fontWeight:'bold', fontSize:'20px', padding:'10px 0px 0px 0px'}}>{row.name} {row.ultimo_nome}</div>
          <br />
          <p style={{width:'100%',textAlign:'justify'}}>
          Dikota { row.name} {row.ultimo_nome} {row.percurso_academico} com experiência profissional 
          nas áreas de {row.percurso_profissional}, residente em {row.location}, disponibiliza-se para transmitir as suas 
          experiências e competências adquiridas nas áreas de {row.interest}.
          </p>
          <br/>
          <p>Não hesite em contactar, pois será muito útil para si.</p>
          <div style={{padding:'15px 0px', textDecoration:'underline'}}>
            <a style={{color:'#E4992A', cursor:'pointer', display:'flex'}}>
              <MessageIcon style={{color:'#E4992A', marginRight:'5px'}} />
              <p>Enviar Mensagem</p>
            </a>
          </div>
        </TableNewColumn>

      </TableNewRow>

      <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <BlogCardDemo dikotas={row} />

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
}





export default function CollapsibleTable({ dikotas }) {

  return (

   dikotas ? (  <TableContainer component={Paper}>
      <Table aria-label="collapsible table" align="center">

        <TableBody>
          {dikotas.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>) :
    (
    
        <div style={{textAlign:'center', fontWeight:'bold', fontSize:'20px', padding:'20px 0px 0px 0px'}}>Aguarde...</div>
    
    )

    
  );
}
